const querystring = require('querystring');
const assign = require('object-assign');

const isArray = Array.isArray;

const endpoint = 'https://www.linkedin.com/oauth/v2/authorization';

function generateLinkedInUrl({ clientId, redirectUri, scope }) {
  const params = {
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: isArray(scope) ? scope.join(' ') : scope,
    state: 'STATE',
    response_type: 'code'
  };
  return endpoint + '?' + querystring.stringify(params);
}

function generatePopupWindowFeatures() {
  const width = 500;
  const height = 500;
  const features = {
    width,
    height,
    left: window.screenX + ((window.outerWidth - width) / 2),
    top: window.screenY + ((window.outerHeight - height) / 2.5)
  };
  return querystring.stringify(features, ',');
}

function pollPopupUpdatesForCode(popup) {
  return new Promise((resolve, reject) => {
    let interval = setInterval(function polling() {
      if (popup.closed) {
        clearInterval(interval);
        reject(new Error("Popup closed"));
        return;
      }

      try {
        popup.location.host;
      } catch (error) {
        // popup host is not accessible because it differs from document host
        // and raises a cross-origin security error
        return;
      }
      if (document.location.host !== popup.location.host) {
        return;
      }
      const queryParams = popup.location.search.substring(1).replace(/\/$/, '');
      const hashParams = popup.location.hash.substring(1).replace(/[\/$]/, '');
      const query = assign(querystring.parse(queryParams), querystring.parse(hashParams));

      clearInterval(interval);
      popup.close();

      query.error ? reject(new Error(query.error)) : resolve(query.code);
    }, 35);
  });
}

export default function linkedInAuthenticate({ redirectUri, clientId, scope }) {
  const url = generateLinkedInUrl({ redirectUri, clientId, scope });
  const popup = window.open(url, "", generatePopupWindowFeatures());
  popup.focus();
  return pollPopupUpdatesForCode(popup);
}

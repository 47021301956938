<template>
  <div class="job__categories">
    <img v-if="isWhite" src="/static/slimicons/seo-seo-tags-white.svg" style="height: 18px" />
    <img v-else src="/static/slimicons/seo-seo-tags.svg" style="height: 18px" />

    <span v-for="category, i in job.categories" class="job__category">
      {{ i18n(`categories/${category.id}`) || category.name }}

      <span v-if="i < job.categories.length - 1">
        &nbsp;&nbsp;—&nbsp;&nbsp;
      </span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'cofomo-job-categories',

  props: ['job', 'isWhite']
}
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .job__categories
    margin-bottom: .2rem
    font-size: .8rem
    line-height: 1.3rem

  .job__categories img
    position: relative
    top: 5px
    margin-right: 0.25rem

  .job__category
    text-transform: uppercase
</style>

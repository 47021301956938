<template>
  <div class="cofomo-social">
    <a :href="facebook" target="_blank">
      <img class="facebook" src="../assets/social/facebook.svg" />
    </a>
    <a :href="twitter" target="_blank">
      <img class="twitter" src="../assets/social/twitter.svg" />
    </a>
    <a :href="linkedIn" target="_blank">
      <img class="linkedin" src="../assets/social/linkedin.svg" />
    </a>
    <a :href="mailto" target="_blank">
      <img class="mail" src="../assets/social/mail.svg" />
    </a>
  </div>
</template>

<script>
  import language from '@/scripts/language'

  export default {
    name: 'cofomo-social',

    props: ['job'],

    computed: {
      title () {
        return this.job.title[language.current]
      },

      url () {
        return `https://carrieres.cofomo.com/${language.current}/jobs/${this.job.id}`
      },

      facebook () {
        const u = window.encodeURIComponent(this.url + `?utm_campaign=partage_site&utm_medium=social&utm_source=facebook`)
        return `https://www.facebook.com/sharer/sharer.php?u=${u}`
      },

      twitter () {
        const text = window.encodeURIComponent(`${this.title} ${this.url  + `?utm_campaign=partage_site&utm_medium=social&utm_source=twitter`}`)
        return `https://twitter.com/intent/tweet?text=${text}`
      },

      linkedIn () {
        const url = window.encodeURIComponent(this.url + `?utm_campaign=partage_site&utm_medium=social&utm_source=linkedin`)
        const title = window.encodeURIComponent(this.title)
        return `http://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`
      },

      mailto () {
        const url = window.encodeURIComponent(this.url + `?utm_campaign=partage_site&utm_medium=social&utm_source=email`)
        const title = window.encodeURIComponent(this.title)
        return `mailto:%20?subject=${title}&body=${url}`
      }
    }
  }
</script>

<style lang="sass" scoped>
  a
    opacity: .75
    transition: opacity .2s

    &:hover
      opacity: 1

  a + a
    margin-left: .5rem

  img
    position: relative
    height: 20px

    &.linkedin
      top: 1px

    &.twitter
      top: 2px

    &.mail
      top: 3px
</style>

<template>
  <nav class="job-nav" v-sticky-state>
    <div class="container">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="job-title level-item">
            <div class="is-cofomo-title-normal">{{ job.title.fr }}</div>
          </div>

          <div class="job-categories level-item is-hidden-mobile">
            <cofomo-job-categories :job="job"></cofomo-job-categories>
          </div>

          <div class="level-item is-hidden-mobile">
            <cofomo-job-metadata :job="job" :elements="['city']"></cofomo-job-metadata>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item">
            <a href="#" class="button is-cofomo-button is-primary gtm-btn-postuler" @click.prevent="$emit('apply')">
              {{ i18n('job/apply') }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  import CofomoJobCategories from '@/components/CofomoJobCategories'
  import CofomoJobMetadata from '@/components/CofomoJobMetadata'

  export default {
    name: 'cofomo-job-nav',

    components: { CofomoJobCategories, CofomoJobMetadata },

    props: ['job']
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .job-nav
    width: 100%
    top: 0
    height: 60px
    z-index: 10
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1)
    visibility: hidden
    transform: translateY(-100%)
    transition: visibility 0s, transform ease .5s
    background: white

  .job-nav.is-sticky
    visibility: visible
    transform: translateY(-0%)

  .job-nav > .container
    height: 100%

  .job-nav > .container > .level
    height: 100%
    padding: 0 1rem

  .level-left
    max-width: calc(100% - 120px)

  .level-left .level-item:not(:last-child)
    margin-right: 1rem

  .level-right .level-item:not(:last-child)
    margin-right: 0

  .level-right .level-item:not(:first-child)
    margin-left: 1rem

  .job-title
    width: 100%

    +tablet
      width: auto
      max-width: 40vw

  .job-title h2
    color: $black
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  .job-categories, .job__metadata
    position: relative
    top: 2px


  /deep/ .job-categories img, /deep/ .job__city img
    top: 1px

  .job__categories
    color: $grey-light
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden

  .job__metadata
    color: $grey-light
</style>

<template>
  <div class="cofomo-notification">
    <b-notification :active.sync="isActive" type="is-primary">
      {{ i18n('home/notification') }}
    </b-notification>
  </div>
</template>

<script>
  import Cookies from 'js-cookie'

  export default {
    name: 'cofomo-notification',

    data () {
      return {
        isActive: Cookies.get('is-notification-closed') !== 'yes'
      }
    },

    watch: {
      isActive (val) {
        if (!val) Cookies.set('is-notification-closed', 'yes')
      }
    }
  }
</script>

<style lang="sass" scoped>
  .cofomo-notification
    position: fixed
    bottom: 1rem
    left: 1rem
    right: 1rem
    z-index: 20

    /deep/ .notification
      max-width: 640px
      margin: 0 auto
      opacity: 0.8
</style>

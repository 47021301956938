<template>
  <div id="app">
    <!-- <cofomo-notification v-if="isDatabaseReachable"></cofomo-notification> -->

    <cofomo-apply ref="apply"></cofomo-apply>
    <cofomo-apply ref="refer" :is-referral="true"></cofomo-apply>

    <transition
      v-if="isDatabaseReachable"
      :name="transitionName"
      :duration="250"
      @after-enter="$events.$emit('router-done')"
      appear
    >
      <router-view></router-view>
    </transition>

    <!-- If database is unreachable, either on page load or request failure -->
    <div v-if="!isDatabaseReachable" class="database-unreachable">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-6-desktop has-text-centered">
            <img class="logo" src="./assets/logo-footer.svg" style="width: 150px">

            <hr class="space space__2" />

            <h1 class="is-cofomo-title-medium">
              {{ i18n('maintenance/title') }}
            </h1>

            <hr class="space space__6" />

            <p class="is-cofomo-subtitle">
              {{ i18n('maintenance/subtitle') }}
            </p>

            <hr class="space space__2" />

            <hr class="space space__separator" />

            <hr class="space space__2" />

            <p>
              {{ i18n('maintenance/alternatives') }}
            </p>

            <hr class="space space__4" />

            <p>
              <a :href="websiteUrl('corporate', '/fr/services')" class="button is-cofomo-button is-primary">
                {{ i18n('maintenance/buttons/services') }}
              </a>
            </p>

            <hr class="space space__4" />

            <p>
              <a :href="websiteUrl('corporate', '/fr/blogue')" class="button is-cofomo-button">
                {{ i18n('maintenance/buttons/blog') }}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>

    <cofomo-footer></cofomo-footer>
  </div>
</template>

<script>
import CofomoApply from '@/components/CofomoApply'
import CofomoFooter from '@/components/CofomoFooter'
import CofomoNotification from '@/components/CofomoNotification'

import language from '@/scripts/language'
import store from '@/store'

export default {
  name: 'cofomo-careers',

  components: { CofomoApply, CofomoFooter, CofomoNotification },

  data () {
    return {
      isDatabaseReachable: window.static.isDatabaseReachable,
      transitionName: 'fade'
    }
  },

  watch: {
    '$route' (to, from) {
      if (from.name === 'Home' && to.name === 'Jobs') {
        this.transitionName = 'to-jobs'
      } else {
        this.transitionName = 'fade'
      }
    }
  },

  mounted () {
    this.$events.$on('unreachable', e => this.isDatabaseReachable = false)

    if (this.$route.query.apply === '1') {
      window.setTimeout(() => this.$refs.apply.launchChooser(), 1000)
    }

    if (this.$route.query.apply === '2') {
      window.setTimeout(() => this.$refs.refer.launchChooser(), 1000)
    }
  },

  beforeDestroy () {
    this.$events.$off('unreachable')
  }
}
</script>

<style lang="sass">
@import "./styles/vars.scss";

#app
  background: $background
  min-height: 100%

.database-unreachable
  padding: 5rem 1rem
</style>

import Vue from 'vue'

import Router from 'vue-router'
import VueScrollTo from 'vue-scrollto'

import Home from '@/pages/Home'
import International from '@/pages/International'
import Jobs from '@/pages/Jobs'
import Job from '@/pages/Job'
import NotFound from '@/pages/NotFound'

import language from '@/scripts/language'

Vue.use(Router)

const router = new Router({
  mode: 'history',

  routes: [
    {
      path: '/:lang(fr|en)/',
      name: 'Home',
      component: Home
    },
    {
      path: '/',
      redirect: to => `/${language.current}/`
    },

    {
      path: '/:lang(fr|en)/jobs',
      name: 'Jobs',
      component: Jobs
    },
    {
      path: '/jobs',
      redirect: to => `/${language.current}/jobs`
    },

    {
      path: '/:lang(fr|en)/jobs/:id',
      name: 'Job',
      component: Job
    },
    {
      path: '/jobs/:id',
      redirect: to => `/${language.current}/jobs/:id`
    },

    {
      path: '/:lang(fr|en)/404',
      name: 'NotFound',
      component: NotFound
    },
    {
      path: '*',
      redirect () {
        document.location = `/${language.current}/404`
      }
    }
  ],

  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(function (to, from, next) {
  if (to.name === from.name) return next()

  VueScrollTo.scrollTo(window.document.body, 250, {
    easing: 'ease-out',
    cancelable: false
  })
  next()
})

export default router

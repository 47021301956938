export default {
  benefits: [
    'design-shape-tools',
    'finance-finance-plan',
    'finance-dollar-loan',
    'party-balloons',
    'education-big-bang-2',
    'arrows-up-arrow-point'
  ],
  international: [
    'logistics-box-hands',
    'camping-place-map-blue',
    'education-science-microscope',
    'camping-map-route',
  ]
}

<template>
  <div class="cofomo-apply">
    <form ref="fileForm" @submit.prevent="">
      <input
        ref="file"
        name="file"
        type="file"
        @change="uploadAndParseResume($event)"
        style="display: none"
      >
    </form>

    <b-modal
      :component="CofomoApplyPrivacyPolicy"
      :active.sync="isPrivacyPolicyActive"
      :animation="animationName"
      @go-to-chooser="goToChooser"
      @close="onModalClose"
      :on-cancel="onModalCancel"
    >
    </b-modal>

    <b-modal
      ref="chooser"
      :component="CofomoApplyChooser"
      :active.sync="isChooserActive"
      :props="{ isParsingResume }"
      :animation="animationName"
      @choose-file="chooseFile"
      @go-to-form="goToForm"
      @fill-with-linked-in="fillWithLinkedIn"
      @close="onModalClose"
      :on-cancel="onModalCancel"
    >
    </b-modal>

    <b-modal
      :component="CofomoApplyForm"
      :active.sync="isFormActive"
      :props="{ filename, isReferral, isSending, model, jobId }"
      :animation="animationName"
      @choose-file="chooseFile"
      @submit="submit"
      @close="onModalClose"
      :on-cancel="onModalCancel"
    >
    </b-modal>
  </div>
</template>

<script>
  import Vue from 'vue'

  import CofomoApplyPrivacyPolicy from '@/components/CofomoApplyPrivacyPolicy'
  import CofomoApplyChooser from '@/components/CofomoApplyChooser'
  import CofomoApplyForm from '@/components/CofomoApplyForm'

  export default {
    name: 'cofomo-apply',

    props: {
      jobId: {
        type: Number
      },

      isReferral: {
        type: Boolean,
        default: false
      }
    },

    data () {
      const isIE = (navigator.appName === 'Microsoft Internet Explorer' ||
        !!navigator.userAgent.match(/Trident/) ||
        !!navigator.userAgent.match(/rv:11/))

      return {
        animationName: isIE ? null : 'zoom-out',

        CofomoApplyPrivacyPolicy,
        CofomoApplyChooser,
        CofomoApplyForm,

        model: {
          city: null,
          email: '',
          firstName: '',
          lastName: '',
          linkedIn: '',
          phone: '',
          isContractor: '',
        },

        filename: '',
        isPrivacyPolicyActive: false,
        isChooserActive: false,
        isFormActive: false,
        isParsingResume: false,
        isSending: false,
        parsedResume: null
      }
    },

    methods: {
      chooseFile () {
        this.filename = ''
        this.$refs.fileForm.reset()
        this.$refs.file.click()
      },

      goToForm () {
        this.isChooserActive = false
        this.isFormActive = true
      },

      fillWithLinkedIn (fields) {
        this.model = Object.assign(this.model, fields)
        this.goToForm()
      },

      launchChooser () {
        this.$events.$emit('videos-pause')
        window.document.body.style.position = 'fixed'
        this.isPrivacyPolicyActive = true
      },

      goToChooser () {
        this.isPrivacyPolicyActive = false

        if (this.isReferral) {
          this.isFormActive = true
        } else {
          this.isChooserActive = true
        }
      },

      cleanup () {
        this.$events.$emit('videos-unpause')
        window.document.body.style.position = 'static'
      },

      onModalCancel () {
        this.cleanup()
      },

      onModalClose () {
        if (!this.isFormActive && !this.isChooserActive) this.cleanup()
      },

      async submit (data) {
        this.isSending = true

        // Build URL with optional query parameters
        let params = []
        if (this.jobId) params.push(`job_id=${this.jobId}`)
        if (this.isReferral) params.push(`referral=true`)
        let url = '/api/candidature?' + params.join('&')

        const formData = new FormData()
        for (let [k, v] of Object.entries(data)) formData.append(k, v)

        const file = this.$refs.file.files[0]
        if (file) formData.append('file', file, file.name)

        try {
          var resp = await window.fetch(url, {
            method: 'POST',
            body: formData
          })

          if (!resp.ok) {
            const json = await resp.json()
            if (resp.status === 400) {
              const message = this.i18n('modals/notifications/error')

              this.isSending = false
              this.isFormActive = false

              window.document.body.style.position = 'static'
              this.$buefy.toast.open({ duration: 5 * 1000, message, type: 'is-danger' })

              return console.error('Could not submit candidature', json.data)
            } else {
              throw new Error(json.data)
            }
          }
        } catch (err) {
          console.error('Database/Network error', err)
          return this.$events.$emit('unreachable')
        }

        // Report to GA
        const gaEvent = {
          event: this.isReferral ? 'referer confirmation' : 'postuler confirmation',
          loginType: data.linkedIn ? 'LinkedIn' : null
        }
        console.info('Sending GA Event', gaEvent)

        // Report to Facebook if Apply
        if (!this.isReferral) {
          window.fbq('track', 'CompleteRegistration')
          console.info('Sending Facebook Pixel Event: CompleteRegistration')
        }

        window.dataLayer.push(gaEvent)

        const message = this.isReferral
          ? this.i18n('modals/notifications/refer-success')
          : this.i18n('modals/notifications/apply-success')

        this.isSending = false
        this.isFormActive = false

        window.document.body.style.position = 'static'
        this.$buefy.toast.open({ duration: 5 * 1000, message, type: 'is-success' })
      },

      async uploadAndParseResume (e) {
        // Get the first of all uploaded files (there should be only one)
        const files = e.target.files || e.dataTransfer.files || []
        const file = files[0]

        // If there's a file (user hasn't cancelled the file dialog), set the filename
        if (file) this.filename = file.name

        // Only parse the file if we're in the chooser
        if (!file || !this.isChooserActive) return

        // Make the linkedin button loading
        this.isParsingResume = true

        // If we're good to proceed, build a FormData
        const body = new FormData()
        body.append('file', file)

        // Try to get the resume information. If the confidence score isn't
        // high enough, this might fail. The resume will still be sent to
        // Bullhorn when the candidature is sent, but it won't be used to
        // prepopulate the fields
        try {
          var resp = await window.fetch('/api/resume', { method: 'POST', body })
        } catch (err) {
          console.error('error', err)
          return
        }

        // `data` will be `{ email, firstName, lastName, phone }`
        const { data, error } = await resp.json()

        // If confidence score is high enough, merge existing model
        // with non-empty data fields
        if (!error) {
          if (data.firstName !== '') this.model.firstName = data.firstName
          if (data.lastName !== '') this.model.lastName = data.lastName
          if (data.email !== '') this.model.email = data.email
          if (data.phone !== '') this.model.phone = data.phone
        }

        // Clean and go to next modal
        this.isParsingResume = false
        this.isChooserActive = false
        this.isFormActive = true
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  /deep/ .modal
    .modal-content
      max-width: 640px !important

    .animation-content
      margin: 0 auto
      padding: 0 20px

    .modal-card
      overflow-y: auto
      width: 100%

    +mobile
      .modal-content
        max-height: 100%

      .modal-card
        width: 100%
        margin: 0

  /deep/ .modal .modal-close
    display: none
</style>

<template>
  <div class="columns is-multiline">
    <div
      v-for="job in jobs"
      @click="navigateTo(job)"
      class="column has-text-left"
      :class="[columnClass]"
    >
      <div class="related-job">
        <p class="job__ref">
          {{ i18n('job/ref') }} &nbsp;<b>{{ job.id }}</b>
        </p>

        <h4 class="is-cofomo-title-small">
          {{ job.title[lang] }}
        </h4>

        <cofomo-job-categories :job="job"></cofomo-job-categories>

        <cofomo-job-metadata :job="job" :elements="['city']"></cofomo-job-metadata>

        <!-- <cofomo-job-metadata :job="job" :elements="['publishedAt']"></cofomo-job-metadata> -->
      </div>
    </div>
  </div>
</template>

<script>
  import CofomoJobCategories from '@/components/CofomoJobCategories'
  import CofomoJobMetadata from '@/components/CofomoJobMetadata'

  import language from '@/scripts/language'
  import gaJobFormat from '@/scripts/ga-job-format'

  export default {
    name: 'cofomo-job-level',

    components: { CofomoJobMetadata, CofomoJobCategories },

    computed: {
      lang () {
        return language.current
      }
    },

    props: {
      jobs: Array,
      columnClass: {
        type: String,
        default: ''
      }
    },

    methods: {
      navigateTo (job) {
        const jobClickGaEvent = {
          event: 'list click',
          list: 'résultats de recherche',
          listing: gaJobFormat([job])
        }
        console.info('Sending GA Event', jobClickGaEvent)
        window.dataLayer.push(jobClickGaEvent)

        this.$emit('job-click', job)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .column
    display: flex
    +mobile
      padding-top: 0
      padding-bottom: 1rem

  .related-job
    width: 100%
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
    padding: 1rem
    font-size: .93060486rem
    background: white
    cursor: pointer
    transition: box-shadow 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)

    &:hover
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)

  .related-job h4
    margin-bottom: .4rem

  .is-cofomo-title-small
    font-size: 1.1rem

  .job__ref
    font-weight: 400
    line-height: 1.25
    text-transform: uppercase
    font-size: 12px
    margin-bottom: .25rem
    letter-spacing: 1px

  .job__ref b
    font-weight: 500
</style>

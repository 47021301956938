<template>
  <div class="youtube-embed" :style="{ 'padding-bottom': paddingBottom }">
    <iframe ref="iframe" :src="src" frameborder="0" allowfullscreen=""></iframe>
  </div>
</template>

<script>
  export default {
    name: 'cofomo-youtube-embed',

    props: {
      id: String,
      ratio: {
        type: Array,
        default () {
          return [16, 9]
        }
      }
    },

    computed: {
      paddingBottom () {
        const [x, y] = this.ratio
        return (y / x * 100) + '%'
      },

      src () {
        return `https://www.youtube.com/embed/${this.id}?rel=0&showinfo=0&enablejsapi=1`
      }
    },

    methods: {
      play () {
        const message = JSON.stringify({ event: 'command', func: 'playVideo', args: [] })
        this.$refs.iframe.contentWindow.postMessage(message, '*')
      },


      pause () {
        const message = JSON.stringify({ event: 'command', func: 'pauseVideo', args: [] })
        this.$refs.iframe.contentWindow.postMessage(message, '*')
      }
    }
  }
</script>

<style scoped>
  .youtube-embed {
    position: relative;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background: #000;
  }

  .youtube-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>

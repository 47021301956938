<template>
  <div class="cofomo-apply-form modal-card">
    <header class="modal-card-head has-text-centered">
      <span class="close-button" @click="$emit('close') && $parent.$emit('close')">
        <b-icon icon="close"></b-icon>
      </span>

      <span
        class="is-cofomo-caps"
        v-text="i18n(isReferral ? 'modals/refer/form/title' : 'modals/apply/form/title')"
      ></span>
    </header>

    <section class="modal-card-body has-text-left">
      <div class="columns responsive-group">
        <div class="column is-half-tablet">
          <b-field
            :label="i18n(isReferral ? 'modals/refer/form/first-name' : 'modals/apply/form/first-name')"
            :type="$v.form.firstName.$error ? 'is-danger' : null"
            :message="$v.form.firstName.$error ? i18n('modals/validations/required') : null"
            expanded
          >
            <b-input
              v-model="form.firstName"
              name="firstName"
              @blur="$v.form.firstName.$touch"
            ></b-input>
          </b-field>
        </div>

        <div class="column is-half-tablet">
          <b-field
            :label="i18n(isReferral ? 'modals/refer/form/last-name' : 'modals/apply/form/last-name')"
            expanded
            :type="$v.form.lastName.$error ? 'is-danger' : null"
            :message="$v.form.lastName.$error ? i18n('modals/validations/required') : null"
          >
            <b-input
              v-model="form.lastName"
              name="lastName"
              @blur="$v.form.lastName.$touch"
            ></b-input>
          </b-field>
        </div>
      </div>

      <b-field
        :label="i18n(isReferral ? 'modals/refer/form/phone' : 'modals/apply/form/phone')"
        :type="$v.form.phone.$error ? 'is-danger' : null"
        :message="$v.form.phone.$error ? i18n('modals/validations/phone') : null"
      >
        <b-input
          v-model="form.phone"
          name="phone"
          @blur="$v.form.phone.$touch"
        ></b-input>
      </b-field>

      <b-field
        :label="i18n(isReferral ? 'modals/refer/form/email' : 'modals/apply/form/email')"
        :type="$v.form.email.$error ? 'is-danger' : null"
        :message="$v.form.email.$error ? i18n('modals/validations/email') : null"
      >
        <b-input
          v-model="form.email"
          name="email"
          type="email"
          @blur="$v.form.email.$touch"
        ></b-input>
      </b-field>

      <b-field
        v-if="!jobId"
        :label="i18n(isReferral ? 'modals/refer/form/city/label' : 'modals/apply/form/city/label')"
        :type="$v.form.city.$error ? 'is-danger' : null"
        :message="$v.form.city.$error ? i18n('modals/validations/required') : null"
      >
        <b-select
          v-model="form.city"
          icon="public"
          :placeholder="i18n('modals/refer/form/city/placeholder')"
          @change="$v.form.city.$touch"
        >
          <option value="Montreal">{{ i18n('modals/refer/form/city/option/montreal') }}</option>
          <option value="Quebec">{{ i18n('modals/refer/form/city/option/quebec') }}</option>
          <option value="Other">{{ i18n('modals/refer/form/city/option/other') }}</option>
        </b-select>
      </b-field>

      <b-field
        v-if="isReferral"
        :label="i18n('modals/refer/form/is-contractor/label')"
        :type="$v.form.isContractor.$error ? 'is-danger' : null"
        :message="$v.form.isContractor.$error ? i18n('modals/validations/required') : null"
      >
        <div>
          <b-radio
            v-model="form.isContractor"
            native-value="1" @change="$v.form.isContractor.$touch"
          >{{ i18n('modals/refer/form/is-contractor/yes') }}</b-radio>
          <b-radio
            v-model="form.isContractor"
            native-value="0"
            @change="$v.form.isContractor.$touch"
          >{{ i18n('modals/refer/form/is-contractor/no') }}</b-radio>
        </div>
      </b-field>

      <b-field :label="i18n(isReferral ? 'modals/refer/form/resume/label' : 'modals/apply/form/resume/label')">
        <b-field grouped>
          <b-input :value="filename" disabled expanded></b-input>

          <p class="control">
            <button
              @click.stop="$parent.$emit('choose-file')"
              class="button is-cofomo-button"
            >
              {{ i18n(isReferral ? 'modals/refer/form/resume/button' : 'modals/apply/form/resume/button') }}
            </button>
          </p>
        </b-field>
      </b-field>
    </section>

    <footer class="modal-card-foot">
      <button
        @click="submit"
        class="button is-cofomo-button is-primary"
        :class="{ 'is-loading': isSending }"
      >
        {{ i18n(isReferral ? 'modals/refer/form/submit-button' : 'modals/apply/form/submit-button') }}
      </button>
    </footer>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { email, required } from 'vuelidate/lib/validators'

export default {
  name: 'cofomo-apply-form',

  mixins: [validationMixin],

  props: ['filename', 'isReferral', 'model', 'jobId', 'isSending'],

  data () {
    return {
      form: this.model
    }
  },

  validations: {
    form: {
      firstName: {
        required,
        isNotEmpty (value) {
          return value.trim() !== ''
        }
      },
      lastName: {
        required,
        isNotEmpty (value) {
          return value.trim() !== ''
        }
      },
      phone: {
        required,
        hasEnoughNumbers (value) {
          return value.replace(/[^\d]/gi, '').length >= 8
        }
      },
      email: { required, email },
      city: {
        isPotentiallyRequired (value) {
          return this.jobId ? true : required(value)
        }
      },
      isContractor: {
        isPotentiallyRequired (value) {
          return this.isReferral ? required(value) : true
        }
      }
    }
  },

  methods: {
    submit () {
      // Trigger potential errors on all fields
      this.$v.form.$touch()

      // Do not proceed if form contains validation errors
      if (this.$v.$invalid) return false

      this.$parent.$emit('submit', this.form)
    }
  }
}
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .cofomo-apply-form
    color: $black

  .modal-card-head
    position: relative
    justify-content: center

  .close-button
    position: absolute
    right: 1rem
    cursor: pointer
    top: 50%
    transform: translateY(-50%)

  .modal-card-body
    padding: 2rem

    +mobile
      padding: 1rem

    /deep/ .input[disabled]
      background-color: #e8e8e8
      color: #505050
      height: 40px

  .modal-card-foot
    justify-content: center

    .is-loading
      color: transparent !important

  .columns.responsive-group
    +mobile
      .column:first-child
        padding-left: 0
        padding-right: 0

      .column:last-child
        padding-left: 0
        padding-right: 0
    +tablet
      .column:first-child
        padding-left: 0

      .column:last-child
        padding-right: 0
</style>

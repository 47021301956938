// Import vendor modules
import _ from 'lodash'
import '@mdi/font/css/materialdesignicons.css'
import Buefy from 'buefy'
import Vue from 'vue'
import VueEvents from 'vue-events'
import VueMoment from 'vue-moment'
import VueTimeago from 'vue-timeago'
import Vuelidate from 'vuelidate'

// Import custom scripts, instances or helpers
import language from '@/scripts/language'
import preventParentScroll from '@/scripts/prevent-parent-scroll'
import StickyState from 'sticky-state'

// Import icons
import icons from './icons'

// Import all CSS
import './styles/global.scss'
import 'vue2-animate/dist/vue2-animate.min.css'

// Import app after importing CSS so embedded styles
// successfully override global styles
import App from './App'
import router from './router.js'

// Config and shortcuts
Vue.config.productionTip = false
window.language = language

// Mixins and vendor components
Vue.use(Buefy)
Vue.use(VueEvents)
Vue.use(VueMoment)
Vue.use(VueTimeago, {
  locales: {
    'en-US': require('vue-timeago/locales/en-US.json'),
    'fr-FR': require('vue-timeago/locales/fr-FR.json')
  }
})

// Custom i18n mixin
Vue.mixin({
  methods: {
    i18n (key) {
      return _.get(window.static.i18n, key + `[${language.current}]`, null)
    },

    slimicon (key) {
      const filename = _.get(icons, key, null)
      return `/static/slimicons/${filename}.svg`
    }
  }
})

Vue.mixin({
  methods: {
    websiteUrl (key, path) {
      return this.hosts[key] + path;
    }
  },

  data () {
    if (process.env.NODE_ENV === 'production') {
      return {
        hosts: {
          corporate: 'https://www.cofomo.com',
          careers:   'https://carrieres.cofomo.com',
          ottawa:    'https://ottawa.cofomo.com'
        }
      }
    } else if (process.env.NODE_ENV === 'staging') {
      return {
        hosts: {
          corporate: 'https://cofomo-staging.heroshop.co',
          careers:   'https://cofomo-careers-staging.herokuapp.com',
          ottawa:    'https://emerion-careers-staging.herokuapp.com'
        }
      }
    } else {
      return {
        hosts: {
          corporate: 'https://cofomo-staging.heroshop.co',
          careers:   'http://localhost:4000',
          ottawa:    'https://emerion-careers-staging.herokuapp.com'
        }
      }
    }
  }
})

Vue.directive('sticky-state', {
  inserted (el) {
    el.classList.add('sticky')
    el.$sticky = new StickyState(el, {})
  },

  componentUpdated (el) {
    el.$sticky.update()
  },

  unbind (el) {
    el.classList.remove('sticky')
    el.$sticky.destroy()
  }
})

;(function tawk (lang) {
  const id = lang === 'fr' ? '1bl10180i' : 'default'

  window.Tawk_API = window.Tawk_API || {}
  window.Tawk_LoadStart = new Date()

  const script = document.createElement('script')
  script.async = true
  script.src = 'https://embed.tawk.to/5968cb786edc1c10b034603a/' + id
  script.charset = 'utf-8'
  script.setAttribute('crossorigin', '*')

  window.document.body.appendChild(script)
})(window.language.current)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: { App }
})

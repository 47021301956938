<template>
  <ul class="job__metadata">
    <li class="job__city" v-if="elements.includes('city') && job.cities.length > 0">
      <img v-if="isWhite" src="/static/slimicons/ecommerce-shop-location-white.svg" style="height: 18px" />
      <img v-else src="/static/slimicons/ecommerce-shop-location.svg" style="height: 18px" />

      {{ i18n(`cities/${job.cities[0].id}`) || job.cities[0].name }}
    </li>

    <li class="job__date" v-if="elements.includes('publishedAt')">
      <img v-if="isWhite" src="/static/slimicons/calendar-wall-clock-2-white.svg" style="height: 18px" />
      <img v-else src="/static/slimicons/calendar-wall-clock-2.svg" style="height: 18px" />

      <timeago :since="job.publishedAt" :locale="locale"></timeago>
    </li>
  </ul>
</template>

<script>
  import language from '@/scripts/language'

  export default {
    name: 'cofomo-job-metadata',

    props: {
      job: {
        type: Object
      },
      elements: {
        type: Array,
        default: () => ['city', 'publishedAt']
      },
      isWhite: Boolean
    },

    computed: {
      locale () {
        return { fr: 'fr-FR', en: 'en-EN' }[language.current]
      }
    }
  }
</script>

<style lang="sass" scoped>
  .job__metadata
    margin-bottom: .2rem
    font-size: .8rem
    line-height: 1.3rem
    text-transform: uppercase

  .job__metadata li
    display: inline-block

  .job__metadata li img
    position: relative
    top: 4px
    margin-right: 0.25rem

  .job__metadata li + li
    margin-left: .5rem

  .job__metadata /deep/ .icon .mdi
    position: relative
    top: -1px
    font-size: 18px
</style>

<template>
  <div class="Jobs">
    <cofomo-nav></cofomo-nav>

    <hr class="space space__4" />

    <cofomo-search
      ref="cofomoSearch"
      @change="search"
      @blur="onSearchBlur"
      v-sticky-state
    ></cofomo-search>

    <hr class="space space__4" />

    <div ref="paginationTop"></div>

    <section class="container">
      <div v-if="jobs.pagination.page === 1" class="has-text-centered">
        <cofomo-apply ref="apply"></cofomo-apply>

        <button
          class="button is-cofomo-button is-primary gtm-btn-postuler"
          @click.prevent="$refs.apply.launchChooser()"
        >
          {{ i18n('home/hero/apply') }}
        </button>

        <hr class="space space__6" />

        <h1 class="matching-jobs">
          <span class="matching-jobs__number">
            {{ jobs.pagination.total }}
          </span>

          <span>
            {{ i18n('search/n-jobs-match') }}
          </span>
        </h1>
      </div>

      <div v-else class="columns is-centered">
        <div class="column is-two-thirds-tablet has-text-centered">
          <b-pagination
            v-show="jobs.pagination.pagesCount > 1"
            @change="loadPageAndScroll"
            :total="jobs.pagination.total"
            :current="currentPage"
            order="is-centered"
            :per-page="jobs.pagination.perPage"
          ></b-pagination>
        </div>
      </div>

      <hr class="space space__3" />

      <div class="jobs container">
        <div class="columns is-centered">
          <div class="column is-two-thirds-tablet">
            <cofomo-job-card
              v-for="job in jobs.data"
              :key="job.id"
              :job="job"
            ></cofomo-job-card>
          </div>
        </div>
      </div>

      <hr class="space space__3" />

      <div class="columns is-centered">
        <div class="column is-two-thirds-tablet has-text-centered">
          <b-pagination
            v-show="jobs.pagination.pagesCount > 1"
            @change="loadPageAndScroll"
            :total="jobs.pagination.total"
            :current="currentPage"
            order="is-centered"
            :per-page="jobs.pagination.perPage"
          ></b-pagination>
        </div>
      </div>

      <hr class="space space__2" />
    </section>
  </div>
</template>

<script>
  import axios from 'axios'
  import qs from 'qs'

  import CofomoApply from '@/components/CofomoApply'
  import CofomoJobCard from '@/components/CofomoJobCard'
  import CofomoNav from '@/components/CofomoNav'
  import CofomoSearch from '@/components/CofomoSearch'

  import language from '@/scripts/language'
  import gaJobFormat from '@/scripts/ga-job-format'
  import store from '@/store'

  const request = axios.create({
    paramsSerializer: function(params) {
      return qs.stringify(params, { arrayFormat: 'brackets' })
    }
  })

  export default {
    name: 'jobs',

    components: { CofomoApply, CofomoJobCard, CofomoNav, CofomoSearch },

    data () {
      return {
        currentPage: store.lastSearchPage,
        jobListEnabled: false,
        jobs: store.lastSearchedJobs,
        searchParams: store.lastSearchParams,
        activeSearch: Promise.resolve()
      }
    },

    computed: {
      isSearchActive () {
        return Object.keys(this.searchParams).length > 0
      },

      static () {
        return window.static
      }
    },

    methods: {
      async loadPage (page, params = this.searchParams) {
        params.page = page

        // Get only english posts if the current language is english
        if (language.current === 'en') params.lang = 'en'

        try {
          const { data: jobs } = await request.get('/api/jobs', { params })

          this.currentPage = page
          this.jobs = jobs
          store.lastSearchedJobs = jobs
          store.lastSearchPage = page

          this.$nextTick(() => this.$refs.cofomoSearch.$el.$sticky.update())
        } catch (err) {
          console.error(err)
          if (err.response.status === 408) this.$events.$emit('unreachable')
        }

        this.activeSearch = Promise.resolve()
      },

      async loadPageAndScroll (page, params) {
        this.activeSearch = this.loadPage(page, params)
        await this.activeSearch
        const { data: jobs, pagination } = this.jobs

        window.scrollTo(0, 113)

        const offset = pagination.perPage * (pagination.page - 1)

        const displayJobsGaEvent = {
          event: 'list impression',
          list: 'list view',
          listing: gaJobFormat(jobs, offset)
        }

        console.info('Sending GA Event', displayJobsGaEvent)
        window.dataLayer.push(displayJobsGaEvent)
      },

      async onSearchBlur () {
        if (this.searchParams.query.trim() === '') return

        await this.activeSearch
        const { data: jobs, pagination } = this.jobs

        const searchGaEvent = {
          event: 'search',
          search: {
            term: this.searchParams.query,
            results: pagination.total
          }
        }

        console.info('Sending GA Event', searchGaEvent)
        dataLayer.push(searchGaEvent)

        const offset = pagination.perPage * (pagination.page - 1)

        const displayJobsGaEvent = {
          event: 'list impression',
          list: 'list view',
          listing: gaJobFormat(jobs, offset)
        }

        console.info('Sending GA Event', displayJobsGaEvent)
        window.dataLayer.push(displayJobsGaEvent)
      },

      search (params) {
        this.searchParams = params
        this.currentPage = 1
        this.activeSearch = this.loadPage(1, params)
      }
    },

    beforeRouteEnter (to, from, next) {
      next(vm => {
        if (from.name === 'Home') {
          vm.$events.$once('router-done', () => vm.$refs.cofomoSearch.focus())
        }
        if (to.query && to.query.city) {
          const cityId = to.query.city
          const city = window.static.cities.find(c => c.id === cityId)
          if (city && store.lastSearchParams.filters.cities.filter(c => c.id === city.id).length === 0) {
            store.lastSearchParams.filters.cities.push(city)

            let newQuery = Object.assign({}, to.query)
            delete newQuery.cities
            vm.$router.replace({ query: newQuery })
          }
        }
      })
    },

    mounted () {
      const displayJobsGaEvent = {
        event: 'list impression',
        list: 'list view',
        listing: gaJobFormat(this.jobs.data)
      }

      console.info('Sending GA Event', displayJobsGaEvent)
      window.dataLayer.push(displayJobsGaEvent)

      for (let i = 0; i < 10; i++) {
        window.setTimeout(() => {
          if(this.$refs.cofomoSearch) this.$refs.cofomoSearch.$el.$sticky.updateBounds()
        }, 500 + i * 100)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .matching-jobs
    font-size: 1.2rem
    font-weight: 400
    line-height: 2rem
    display: flex
    justify-content: center
    align-items: center

  .matching-jobs__number
    font-size: 2rem
    margin-right: .5rem
    border-bottom: 2px solid $primary

  .job + .job
    margin-top: 2rem

    +mobile
      margin-top: 1rem

  /deep/ .pagination
    display: inline-flex

  /deep/ .pagination a
    background: white

  /deep/ .pagination a.is-current
    color: $black

</style>

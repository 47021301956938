<template>
  <div class="job" v-if="job">
    <cofomo-nav></cofomo-nav>

    <section
      class="hero"
      :style="{ 'background-image': backgroundImage }"
    >
      <div class="hero__shader"></div>

      <div class="hero-body has-text-centered">
        <p class="job__ref">
          {{ i18n('job/ref') }} &nbsp;<b>{{ job.id }}</b>
        </p>

        <h1 class="job__title title is-2 has-text-centered">
          {{ title }}
        </h1>

        <cofomo-job-categories :job="job" :is-white="true"></cofomo-job-categories>

        <cofomo-job-metadata :job="job" :is-white="true" :elements="['city']"></cofomo-job-metadata>

        <div v-if="isFromSearch">
          <hr class="space space__4" />

          <router-link :to="{ name: 'Jobs' }" class="button is-cofomo-button is-black">
            <svg version="1.1" viewBox="0 0 24 24" style="height: 12px; margin-top: -1px">
              <g fill="#FFFFFF">
                <path d="M13.169,23.932c0.095,0.083 0.213,0.125 0.331,0.125c0.138,0 0.276,-0.057 0.375,-0.169c0.183,-0.207 0.164,-0.522 -0.043,-0.706l-12.578,-11.125l12.577,-11.125c0.207,-0.183 0.227,-0.499 0.043,-0.706c-0.184,-0.208 -0.5,-0.226 -0.706,-0.043l-13,11.5c-0.106,0.094 -0.168,0.231 -0.168,0.374c0,0.143 0.062,0.28 0.169,0.375l13,11.5Z" transform="translate(5, -0.057)"></path>
              </g>
            </svg>
            &nbsp;&nbsp;
            {{ i18n('job/back-to-search') }}
          </router-link>
        </div>
      </div>
    </section>

    <section class="white">
      <cofomo-job-nav ref="jobNav" :job="job" @apply="$refs.apply.launchChooser()"></cofomo-job-nav>

      <div class="columns is-centered">
        <div class="column is-10-tablet is-two-thirds-desktop">
          <section class="page padded-container">
            <cofomo-social :job="job"></cofomo-social>

            <h2 class="job__about-title title is-3">
              {{ i18n('job/about') }}
            </h2>

            <div
              class="job__about-description content"
              v-html="description"
            ></div>

            <hr class="space space__6" />

            <div
              class="job__notice is-text-grey-light is-cofomo-caps is-medium has-text-centered"
              v-html="i18n('job/notice')"
            ></div>

            <hr class="space space__2" />

            <div class="job__apply has-text-centered is-text-white material-shadow gradient-background-1">
              <p>
                <cofomo-apply ref="apply" :job-id="job.id"></cofomo-apply>

                <a
                  @click.prevent="$refs.apply.launchChooser()"
                  href="#"
                  class="button is-cofomo-button is-white is-large gtm-btn-postuler"
                >{{ i18n('job/apply') }}</a>
              </p>

              <hr class="space space__6" />

              <p>
                <cofomo-apply ref="refer" :job-id="job.id" :is-referral="true"></cofomo-apply>

                <a
                  @click.prevent="$refs.refer.launchChooser()"
                  href="#"
                  class="job__apply__referral gtm-btn-referer"
                >{{ i18n('job/refer') }}</a>
              </p>
            </div>

            <hr class="space space__2" />

            <div class="job__about material-shadow has-text-centered">
              <h2 class="job__related__title is-cofomo-caps">
                {{ i18n('job/about-cofomo/title') }}
              </h2>

              <hr class="space space__3" />

              <div class="content">
                {{ i18n('job/about-cofomo/content') }}
              </div>
            </div>

          </section>
        </div>
      </div>
    </section>

    <section class="job__about-cofomo has-text-left">
      <div class="columns is-centered">
        <div class="column is-10-tablet is-two-thirds-widescreen">
          <div class="padded-container has-text-centered">
            <h2 class="job__related__title is-cofomo-caps">
              {{ i18n('job/interested') }}
            </h2>

            <hr class="space space__3" />

            <cofomo-job-level
              :jobs="relatedJobs"
              @job-click="navigateToRelatedJob($event)"
              column-class="is-full is-one-third-desktop"
            ></cofomo-job-level>

            <hr class="space space__2" />

            <router-link :to="{ name: 'Jobs' }" class="button is-cofomo-button is-outlined">
              {{ i18n('job/all') }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import axios from 'axios'

  import CofomoApply from '@/components/CofomoApply'
  import CofomoJobCategories from '@/components/CofomoJobCategories'
  import CofomoJobLevel from '@/components/CofomoJobLevel'
  import CofomoJobMetadata from '@/components/CofomoJobMetadata'
  import CofomoJobNav from '@/components/CofomoJobNav'
  import CofomoNav from '@/components/CofomoNav'
  import CofomoSocial from '@/components/CofomoSocial'

  import gaJobFormat from '@/scripts/ga-job-format'
  import language from '@/scripts/language'

  async function getJob (id) {
    const { data: job } = await axios.get(`/api/jobs/${id}`)

    const displayJobGaEvent = {
      event: 'job ad details loaded',
      page: {
        lang: 'fr',
        type: 'single view'
      },
      job: gaJobFormat([job])[0],
    }
    console.info('Sending GA Event', displayJobGaEvent)
    window.dataLayer.push(displayJobGaEvent)

    const relatedJobsGaEvent = {
      event: 'list impression',
      list: 'annonces semblables',
      listing: gaJobFormat(job.relatedJobs)
    }
    console.info('Sending GA Event', relatedJobsGaEvent)
    window.dataLayer.push(relatedJobsGaEvent)

    return job
  }

  export default {
    name: 'job',

    components: {
      CofomoApply,
      CofomoJobCategories,
      CofomoJobLevel,
      CofomoJobMetadata,
      CofomoJobNav,
      CofomoNav,
      CofomoSocial
    },

    data () {
      const backgroundI = Math.floor(Math.random() * 13) + 1

      return {
        backgroundImage: `url("/static/images/jobs/${backgroundI}.jpg")`,
        job: null,
        isFromSearch: this.$route.query['from_search'] !== void 0,
      }
    },

    computed: {
      title () {
        return this.job ? this.job.title[language.current] : null
      },


      description () {
        return this.job ? this.job.description[language.current] : null
      },

      relatedJobs () {
        return language.current === 'en'
          ? this.job.relatedJobsInEnglish
          : this.job.relatedJobs
      }
    },

    methods: {
      navigateToRelatedJob (job) {
        const gaEvent = {
          event: 'list click',
          list: 'annonces semblables',
          listing: gaJobFormat([job])
        }
        console.info('Sending GA Event', gaEvent)
        window.dataLayer.push(gaEvent)

        this.$router.push({
          name: 'Job',
          params: { id: job.id }
        })
      }
    },

    mounted () {
      for (let i = 0; i < 10; i++) {
        window.setTimeout(() => {
          if(this.$refs.jobNav) this.$refs.jobNav.$el.$sticky.updateBounds()
        }, 500 + i * 100)
      }
    },

    async beforeRouteEnter (to, from, next) {
      try {
        const job = await getJob(to.params.id)

        if (language.current === 'en' && !job.isAvailableInEnglish) {
          return next(vm => vm.$router.push({ name: 'NotFound', query: { from: to.params.id } }))
        } else if (language.current === 'fr' && !job.isAvailableInFrench) {
          return next(vm => vm.$router.push({ name: 'NotFound', query: { from: to.params.id } }))
        } else {
          return next(vm => vm.job = job)
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          return next(vm => vm.$router.push({ name: 'NotFound' }))
        }

        return next(vm => vm.$events.$emit('unreachable'))
      }
    },

    async beforeRouteUpdate (to, from, next) {
      this.job = null

      try {
        this.job = await getJob(to.params.id)
        next()
      } catch (err) {
        if (err.response && err.response.status === 404) {
          return this.$router.replace({ name: 'NotFound' })
        }

        this.$events.$emit('unreachable')
        next()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .job__categories, .job__metadata
    display: inline-block

  .job__categories
    margin-right: .5rem

  .hero
    position: relative
    background-size: cover
    background-position: top
    padding-bottom: 6rem

    &, .title, .subtitle, strong
      color: white

  .hero__shader
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background: black
    opacity: 0.3

  .hero-body
    position: relative

  .white
    background: white

  .page
    position: relative
    background: white
    margin-top: -10rem
    border-top: 10px solid $primary
    padding-top: 3rem
    padding-bottom: 3rem

  .job__title
    font-weight: 500

  .job__ref
    font-weight: 400
    line-height: 1.25
    text-transform: uppercase
    font-size: 14px
    margin-bottom: 1rem
    letter-spacing: 1px

  .job__ref b
    font-weight: 500

  .cofomo-social
    float: right
    position: relative
    top: 10px

    @media screen and (max-width: 400px)
      float: none
      margin-top: -2rem
      text-align: center
      margin-bottom: 1rem

  .job__about-title
    font-weight: 500

  .job__about-description
    font-size: 1.1em

  .job__notice
    max-width: 750px
    margin: 0 auto

  .job__apply
    max-width: 275px
    margin: 0 auto
    padding: 2rem
    border-radius: 5px

  .job__apply__title
    font-weight: 500
    font-size: 1.1em
    letter-spacing: 0.15px

  .job__apply__button

  .job__apply__referral
    color: white
    border-bottom: 1px solid rgba(255, 255, 255, 0.5)
    padding-bottom: 3px

  .job__social li
    display: inline-block

  .job__related
    padding: 5rem 0

  .job__related__title
    font-weight: 500

  .job__about
    padding: 2rem 1rem
    background: $background

    +desktop
      padding: 2rem

  .job__about-cofomo
    padding-top: 3rem
    padding-bottom: 3rem
    margin-top: 11px
    border-top: 1px solid $grey-lighter
</style>

<template>
  <div class="not-found">
    <cofomo-nav></cofomo-nav>

    <div class="container">
      <div class="columns is-centered">
        <div class="column is-6-desktop has-text-centered">
          <img class="logo" src="../assets/logo-footer.svg" style="width: 150px">

          <hr class="space space__2" />

          <template v-if="$route.query.from">
            <h1 class="is-cofomo-title-medium">
              {{ i18n('not-found/job/title') }}
            </h1>

            <hr class="space space__6" />

            <p class="is-cofomo-subtitle">
              {{ i18n('not-found/job/subtitle') }}
            </p>

            <hr class="space space__2" />

            <p>
              <a :href="fallbackUrl" class="button is-cofomo-button is-primary">
                {{ i18n('not-found/job/read-french') }}
              </a>
            </p>
          </template>

          <template v-else>
            <h1 class="is-cofomo-title-medium">
              {{ i18n('not-found/title') }}
            </h1>

            <hr class="space space__6" />

            <p class="is-cofomo-subtitle">
              {{ i18n('not-found/subtitle') }}
            </p>
          </template>

          <hr class="space space__2" />

          <hr class="space space__separator" />

          <hr class="space space__2" />

          <p>
            <router-link :to="{ name: 'Home' }" class="button is-cofomo-button" :class="{ 'is-primary': !$route.query.from }">
              {{ i18n('not-found/back-to-site') }}
            </router-link>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CofomoNav from '@/components/CofomoNav'

  import language from '@/scripts/language'

  export default {
    name: 'notfound',

    components: { CofomoNav },

    mounted () {
      const gaEvent = { error: '404' }
      console.info('Sending GA Event', gaEvent)
      window.dataLayer.push(gaEvent)
    },

    data () {
      return {
        fallbackUrl: (language.current === 'en' ? '/fr' : '/en') + '/jobs/' + this.$route.query['from']
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .not-found > .container
    padding: 5rem 1rem
</style>

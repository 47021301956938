import language from '@/scripts/language'

const yyyymmdd = timestamp => {
  const date = new Date(timestamp)
  const yyyy = date.getFullYear()

  let mm = date.getMonth() + 1
  if (mm < 10) mm = '0' + mm

  let dd = date.getDate()
  if (dd < 10) dd = '0' + dd

  return `${yyyy}-${mm}-${dd}`
}

export default function gaJobFormat (jobs, offset = 0) {
  return jobs.map((job, i) => {
    return {
      id: job.id,
      title: job.title.fr,
      city: (job.cities[0] || {}).name,
      categories: job.categories.map(c => c.name),
      lang: language.current,
      pubDate: yyyymmdd(job.publishedAt),
      endDate: yyyymmdd(job.startsAt),
      position: offset + i + 1
    }
  })
}

<template>
  <div class="cofomo-apply-chooser modal-card">
    <header class="modal-card-head has-text-centered">
      <span class="close-button" @click="$emit('close') && $parent.$emit('close')">
        <b-icon icon="close"></b-icon>
      </span>

      <span class="is-cofomo-caps">
        {{ i18n('modals/apply/chooser/title') }}
      </span>
    </header>

    <section class="modal-card-body">
      <p class="has-text-centered">
        {{ i18n('modals/apply/chooser/text') }}
      </p>

      <hr class="space space__5" />

      <div class="choices">
        <a
          href="#"
          @click.prevent="chooseFile"
          class="button is-cofomo-button is-secondary"
          :class="{ 'is-loading': isParsingResume }"
        >
          <img class="cv" src="/static/slimicons/mail-file-attachment.svg" />
          {{ i18n('modals/apply/chooser/cv-button') }}
        </a>

        <a
          href="#"
          @click.prevent="getLinkedInProfile"
          class="button is-cofomo-button is-primary"
          :class="{ 'is-loading': isAuthenticatingToLinkedIn }"
        >
          <img class="linkedin" src="../assets/social/linkedin-white.svg" />
          {{ i18n('modals/apply/chooser/linkedin-button') }}
        </a>
      </div>
    </section>

    <footer class="modal-card-foot has-text-centered">
      <a @click="$parent.$emit('go-to-form')">
        {{ i18n('modals/apply/chooser/manual-button') }}
      </a>
    </footer>
  </div>
</template>

<script>
  import linkedInAuthenticate from '@/scripts/oauth-linkedin'

  export default {
    name: 'cofomo-apply-chooser',

    props: {
      isParsingResume: Boolean
    },

    data () {
      return {
        isAuthenticatingToLinkedIn: false
      }
    },

    methods: {
      chooseFile () {
        this.$parent.$emit('choose-file')
      },

      async getLinkedInProfile () {
        if (this.isAuthenticatingToLinkedIn) return
        this.isAuthenticatingToLinkedIn = true

        try {
          var profile = await this.authenticateToLinkedIn()
        } catch (err) {
          console.error('Could not authenticate to LinkedIn')
          console.error(err)
          this.isAuthenticatingToLinkedIn = false
          return
        }

        // Filter and rename fields returned by the public profile query
        const { email, firstName, lastName, linkedIn } = profile

        // Emit them to the Apply parent
        this.$parent.$emit('fill-with-linked-in', { email, firstName, lastName, linkedIn })
      },

      async authenticateToLinkedIn () {
        const redirectUri = window.static.LINKEDIN_REDIRECT_URL
        const clientId = window.static.LINKEDIN_CLIENT_ID

        const code = await linkedInAuthenticate({
          redirectUri,
          clientId,
          scope: ['r_liteprofile', 'r_emailaddress']
        })

        const resp = await fetch(redirectUri + 'linkedin', {
          method: 'POST',
          headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
          body: JSON.stringify({ redirect_uri: redirectUri, client_id: clientId, code })
        })

        return resp.json()
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .cofomo-apply-chooser
    color: $black

  .modal-card-head
    position: relative
    justify-content: center

  .close-button
    position: absolute
    right: 1rem
    cursor: pointer
    top: 50%
    transform: translateY(-50%)

  .modal-card-body
    padding: 2rem

    +mobile
      padding: 1rem

  .modal-card-foot
    justify-content: center

  .choices a
    position: relative
    max-width: 300px
    display: block
    margin-left: auto
    margin-right: auto

    &.is-loading
      color: transparent !important

      @media screen and (max-width: 400px)
        &::after
          margin-top: 15px

    & + a
      margin-top: 1rem

    img
      position: absolute
      top: 50%
      left: 1rem
      transform: translateY(-50%)
      height: 20px
      margin-right: .5rem

    img.cv
        margin-top: -2px
        margin-left: -2px

    @media screen and (max-width: 400px)
      img, img.cv
        position: relative
        display: block
        margin: 0 auto
        margin-top: 10px
        left: 0
        top: 10px

  /deep/ .icon .mdi
    position: relative
    top: 1px
    font-size: 20px
    margin-right: 1rem

    &:before
      font-size: 20px
      height: 20px
</style>

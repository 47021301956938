import { render, staticRenderFns } from "./CofomoJobCategories.vue?vue&type=template&id=267fbecc&scoped=true&"
import script from "./CofomoJobCategories.vue?vue&type=script&lang=js&"
export * from "./CofomoJobCategories.vue?vue&type=script&lang=js&"
import style0 from "./CofomoJobCategories.vue?vue&type=style&index=0&id=267fbecc&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "267fbecc",
  null
  
)

export default component.exports
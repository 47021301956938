<template>
  <footer class="cofomo-footer">
    <div class="container">
      <div class="columns is-multiline is-mobile">
        <div class="column is-half-mobile is-one-third-tablet is-2-widescreen is-offset-1-widescreen">
          <div class="footer-section-title">{{ i18n('home/hero/site-name') }}</div>

          <ul>
            <li>
              <router-link :to="{ name: 'Jobs' }" :title="i18n('home/go-to-jobs/link-title')">
                {{ i18n('home/go-to-jobs') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Home', hash: '#benefits' }" :title="i18n('home/benefits/link-title')">
                {{ i18n('home/benefits') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Home', hash: '#referral' }" :title="i18n('home/referral/link-title')">
                {{ i18n('home/referral') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'Home', hash: '#contact' }" :title="i18n('home/contact/link-title')">
                {{ i18n('home/contact') }}
              </router-link>
            </li>
          </ul>
        </div>

        <div class="column is-half-mobile is-one-third-tablet is-2-widescreen">
          <div class="footer-section-title">{{ i18n('footer/section/corporate') }}</div>

          <ul>
            <li>
              <a :href="i18n('footer/corporate/home/link')" :title="i18n('footer/corporate/home/link-title')" target="_blank">
                {{i18n('footer/corporate/home/link-title')}}
              </a>
            </li>
            <li>
              <a :href="i18n('footer/corporate/about/link')" :title="i18n('footer/corporate/about/link-title')" target="_blank">
                {{i18n('footer/corporate/about/link-title')}}
              </a>
            </li>
            <li>
              <a :href="i18n('footer/corporate/team/link')" :title="i18n('footer/corporate/team/link-title')" target="_blank">
                {{i18n('footer/corporate/team/link-title')}}
              </a>
            </li>
            <li>
              <a :href="i18n('footer/corporate/contact/link')" :title="i18n('footer/corporate/contact/link-title')" target="_blank">
                {{i18n('footer/corporate/contact/link-title')}}
              </a>
            </li>
            <li>
              <a :href="i18n('footer/corporate/news/link')" :title="i18n('footer/corporate/news/link-title')" target="_blank">
                {{i18n('footer/corporate/news/link-title')}}
              </a>
            </li>
          </ul>
        </div>

        <div class="column is-half-mobile is-one-third-tablet is-2-widescreen">
          <div class="footer-section-title">{{ i18n('footer/section/others') }}</div>
          <ul>
            <li>
              <a :href="i18n('footer/legal-notice/link')" rel="noopener nofollow" target="_blank">
                {{ i18n('footer/legal-notice/link-title') }}
              </a>
            </li>
            <li>
              <a :href="i18n('footer/privacy-policy/link')" rel="noopener nofollow" target="_blank">
                {{ i18n('footer/privacy-policy/link-title') }}
              </a>
            </li>
          </ul>
        </div>

        <div class="column is-half-mobile is-one-third-tablet is-2-widescreen">
          <div class="footer-section-title">{{ i18n('footer/section/languages') }}</div>

          <ul>
            <li>
              <a :href="language.getLanguageUrl('fr')" rel="noopener nofollow">
                Français
              </a>
            </li>
            <li>
              <a :href="language.getLanguageUrl('en')" rel="noopener nofollow">
                English
              </a>
            </li>
          </ul>
        </div>

        <div class="column is-half-mobile is-one-third-tablet is-2-widescreen">
          <a :href="i18n('footer/logo/link')" :title="i18n('footer/logo/link-title')" target="_blank">
            <img class="logo" src="../assets/logo-footer.svg" style="width: 150px">
          </a>

          <p class="copy">
            &copy; {{ (new Date()).getFullYear() }} Cofomo Inc.
          </p>

          <div class="social">
            <a :href="i18n('footer/linkedin/link')" target="_blank" style="width: 16px;" :title="i18n('footer/linkedin/link-title')">
              <img src="../assets/footer/linkedin.svg" style="height: 15px; margin-top: -1px;">
            </a>
            <a :href="i18n('footer/facebook/link')" target="_blank" style="width: 8px;" :title="i18n('footer/facebook/link-title')">
              <img src="../assets/footer/facebook.svg">
            </a>
            <a :href="i18n('footer/vimeo/link')" target="_blank"  :title="i18n('footer/vimeo/link-title')">
              <img src="../assets/footer/vimeo.svg" style="height: 14px; position: relative; top: 1px;">
            </a>
            <a :href="i18n('footer/instagram/link')" target="_blank">
              <img src="../assets/footer/instagram.svg" style="height: 16px; position: relative; top: 2px;"  :title="i18n('footer/instagram/link-title')">
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  import language from '@/scripts/language'

  export default {
    name: 'cofomo-footer',

    computed: {
      lang () {
        return language.current
      },

      language () {
        return language
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .cofomo-footer
    border-top: 1px solid #e5e5e5
    padding: 5rem 1rem
    background: white

    +desktop
      padding-left: 0
      padding-right: 0

  .footer-section-title
    color: #8b959f
    margin-bottom: 1.5em
    font-weight: 600
    font-size: 12px
    text-transform: uppercase
    letter-spacing: 1px
    line-height: 1.2

  ul
    list-style-type: none
    margin: 0
    text-transform: uppercase

  ul li
    margin-bottom: 0.25em

  ul li a
    transition: color 0.2s
    color: #adadad
    font-weight: 400
    font-size: 12px

    &:hover
      color: #3c4146

  img.logo
    margin-bottom: 12px
    max-width: 100%

  p.copy
    color: #adadad
    font-weight: 600
    text-transform: uppercase
    margin-bottom: 12px
    font-size: 12px

  .social a
    display: inline-block

    &:not(:last-child)
      margin-right: 10px
</style>

<template>
  <div class="job card" @click="navigateToJob()">
    <div class="card-content">
      <div class="columns is-three-quarters-tablet">
        <div class="infos column">
          <p class="job__ref">
            {{ i18n('job/ref') }} &nbsp;<b>{{ job.id }}</b>
          </p>

          <h2 class="title is-5">
            {{ job.title[lang] }}
          </h2>


        </div>

        <div class="start column">
          <div>
            <cofomo-job-categories :job="job"></cofomo-job-categories>

            <cofomo-job-metadata :job="job" :elements="['city']"></cofomo-job-metadata>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CofomoJobCategories from '@/components/CofomoJobCategories'
  import CofomoJobMetadata from '@/components/CofomoJobMetadata'

  import language from '@/scripts/language'
  import gaJobFormat from '@/scripts/ga-job-format'

  export default {
    name: 'cofomo-job-card',

    components: { CofomoJobCategories, CofomoJobMetadata },

    props: ['job'],

    computed: {
      lang () {
        return language.current
      }
    },

    methods: {
      navigateToJob () {
        const jobClickGaEvent = {
          event: 'list click',
          list: 'résultats de recherche',
          listing: gaJobFormat([this.job])
        }
        console.info('Sending GA Event', jobClickGaEvent)
        window.dataLayer.push(jobClickGaEvent)

        this.$router.push({
          name: 'Job',
          params: { id: this.job.id },
          query: { 'from_search': 1 }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .job
    cursor: pointer

  .job__ref
    font-weight: 400
    line-height: 1.25
    text-transform: uppercase
    font-size: 14px
    margin-bottom: .25rem
    letter-spacing: 1px

  .job__ref b
    font-weight: 500

  /deep/ .job__categories
    color: $grey

  /deep/ .job__metadata
    color: $grey

  .title
    font-weight: 500
    font-size: 1.2em
    margin-bottom: .25rem
    line-height: 1.5

  .infos
    +mobile
      padding-bottom: 0

  .start
    +tablet
      text-align: right

  .start__title
    font-size: .8em
    text-transform: uppercase
    color: $grey

  .start__date
    font-size: 1.3em
</style>

import Vue from 'vue'

export default new Vue({
  data () {
    return {
      lastSearchedJobs: {},
      lastSearchPage: 1,
      lastSearchParams: {
        query: '',
        filters: {
          cities: [],
          categories: []
        }
      }
    }
  }
})

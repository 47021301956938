<template>
<div class="cofomo-search material-shadow">
  <div
    ref="static"
    class="cofomo-search__static"
  >
    <resize-sensor @resize="resize"></resize-sensor>

    <!-- Text input field -->
    <b-field class="cofomo-search__field">
      <img src="/static/slimicons/ui-search.svg" style="height: 25px" />

      <b-input
        ref="searchField"
        v-model.lazy.trim="params.query"
        :placeholder="i18n('search/placeholder')"
        type="search"
        @blur="$emit('blur')"
      ></b-input>
    </b-field>

    <!-- Active filters -->
    <div
      class="cofomo-search__filters--active"
      v-show="filtersEnabled">
      <template v-for="filterType in filterTypes">
        <a
          v-for="obj in params.filters[filterType.id] || []"
          @click.prevent="toggleFilter(filterType.id, obj)"
          href="#"
        >
          <span
            class="cofomo-search__tag tag is-rounded"
            :class="['is-' + filterType.color]"
          >
            {{ i18n(`${filterType.id}/${obj.id}`) || obj.name }}
            <a class="delete"></a>
          </span>
        </a>
      </template>
    </div>

    <!-- Filter tabs -->
    <div
      :class="{ 'cofomo-search__tabs--is-active': !!activeFilterTabId }"
      class="cofomo-search__tabs columns is-gapless is-marginless"
    >
      <div
        v-for="filterType in filterTypes"
        :class="[
          'cofomo-search__tab column is-one-half cofomo-search__tab--' + filterType.id,
          activeFilterTabId === filterType.id ? 'cofomo-search__tab--is-active': ''
        ]"
      >
        <a href="#" @click.prevent="toggleFilterTab(filterType.id)">
          <span class="cofomo-search__tab__text">
            {{ filterType.name }}
          </span>
          <b-icon icon="chevron-down" size="is-small"></b-icon>
        </a>
      </div>
    </div>
  </div>

  <transition name="slide-up" v-on:after-leave="showNextActiveFilterTabId">
    <div
      ref="drawer"
      v-show="!!activeFilterTabId"
      class="cofomo-search__drawer"
      :style="{ 'max-height': maxHeight }"
    >
      <a
        v-for="obj in filters[activeFilterTabId]"
        @click.prevent="toggleFilter(activeFilterTabId, obj)"
        href="#"
      >
        <span class="cofomo-search__tag cofomo-search__tag--inactive tag is-rounded">
          {{ i18n(`${activeFilterTabId}/${obj.id}`) || obj.name }}
          <sup>{{ obj.jobOrderIds[lang].length }}</sup>
        </span>
      </a>
    </div>
  </transition>
</div>
</template>

<script>
import ResizeSensor from 'vue-resize-sensor'

import preventParentScroll from '@/scripts/prevent-parent-scroll'
import store from '@/store'
import language from '@/scripts/language'

const OFFSET_DRAWER_BOTTOM = 20

export default {
  name: 'cofomo-search',

  components: { ResizeSensor },

  data () {
    return {
      height: 'auto',
      activeFilterTabId: null,
      nextActiveFilterTabId: null,
      filterTypes: [
        { id: 'cities', name: this.i18n('search/city'), color: 'info' },
        { id: 'categories', name: this.i18n('search/expertise'), color: 'success' }
      ],
      offsetTop: 0,
      params: store.lastSearchParams
    }
  },

  computed: {
    static () {
      return window.static
    },

    lang () {
      return language.current
    },

    filters () {
      let categories = window.static.categories.filter(category => category.jobOrderIds[language.current].length > 0)
      let cities = window.static.cities.filter(city => city.jobOrderIds[language.current].length > 0)
      return { categories, cities }
    },

    filtersEnabled () {
      return Object.keys(this.params.filters).reduce(
        (m, v) => m += this.params.filters[v].length, 0
      ) !== 0
    },

    activeFilterClass () {
      const state = this.filterTypes.find(filterType =>
        filterType.id === this.activeFilterTabId
      ).color

      return `is-${state}`
    },

    maxHeight () {
      return '100vh'
      // return this.gluebarStatus === -1
      //   ? `calc(100vh - ${this.offsetTop + this.height}px - 20px)`
      //   : `calc(100vh - ${this.height}px)`
    }
  },

  watch: {
    params: {
      handler: function () {
        store.lastSearchParams = this.params

        const filters = Object.keys(this.params.filters).reduce((filters, filterType) => {
          filters[filterType] = this.params.filters[filterType]
            .map(el => el.slug)
            .filter(el => !!el)

          return filters
        }, {})

        this.$emit('change', { query: this.params.query, filters })
      },
      deep: true
    }
  },

  methods: {
    // Public function that focuses on the input of the searchField component,
    // meant to be called from outer scope
    focus () {
      this.$refs.searchField.$refs.input.focus()
    },

    closeDrawerOnOutsideClick (e) {
      if (!this.$el.contains(e.target)) {
        this.activeFilterTabId = null
      }
    },

    gluebarChange (status) {
      // this.gluebarStatus = status

      const method = status === 0 ? 'addEventListener' : 'removeEventListener'

      for (const el of [this.$refs.static, this.$refs.drawer]) {
        el[method]('DOMMouseScroll', preventParentScroll)
        el[method]('mousewheel', preventParentScroll)
      }
    },

    resize ({ height }) {
      this.height = height
    },

    showNextActiveFilterTabId () {
      this.activeFilterTabId = this.nextActiveFilterTabId
      this.nextActiveFilterTabId = null
    },

    toggleFilter (filterType, obj) {
      // Try to find the index of an existing filter with the same id
      // as the one we want to toggle
      const existingI = this.params.filters[filterType].findIndex(existingObj =>
        existingObj.id === obj.id
      )

      // If the index exists, remove it from the filter collection
      if (existingI >= 0) {
        this.params.filters[filterType].splice(existingI, 1)
      // Otherwise, add it to the end of the collection
      } else {
        this.params.filters[filterType].push(obj)

        // Report to GA
        const gaEvent = {
          event: 'click filtre',
          filter: {
            type: filterType,
            value: obj.name
          }
        }
        console.info('Sending GA Event', gaEvent)
        window.dataLayer.push(gaEvent)
      }

      this.activeFilterTabId = null
    },

    toggleFilterTab (filterType) {
      if (this.activeFilterTabId) {
        if (this.activeFilterTabId !== filterType) this.nextActiveFilterTabId = filterType
        this.activeFilterTabId = null
      } else {
        this.activeFilterTabId = filterType
      }
    }
  },

  mounted () {
    window.addEventListener('click', this.closeDrawerOnOutsideClick)

    window.setTimeout(() => {
      this.offsetTop = this.$el.getBoundingClientRect().top - document.body.scrollTop
    }, 300)
  },

  beforeDestroy () {
    window.removeEventListener('click', this.closeDrawerOnOutsideClick)

    for (const el of [this.$refs.static, this.$refs.drawer]) {
      el.removeEventListener('DOMMouseScroll', preventParentScroll)
      el.removeEventListener('mousewheel', preventParentScroll)
    }
  }
}
</script>

<style lang="sass" scoped>
@import "../styles/vars.scss";

.sticky-wrap .sticky-fixed:not(.is-sticky)
  position: relative

.cofomo-search
  // IE11
  width: 100%
  top: 0
  max-width: 759px
  background: white
  z-index: 10
  transition: max-width ease-out .1s
  margin-left: auto
  margin-right: auto

.cofomo-search.is-sticky
  max-width: 100%
  z-index: 10

// Filters

.cofomo-search__filters--active
  text-align: left
  padding: .5rem
  border-bottom: 1px solid $grey-lighter
  background: white
  line-height: 2.5rem
  max-height: 100px
  overflow: auto

// Search input

.cofomo-search__field
  margin-bottom: 0
  border-bottom: 1px solid $grey-lighter

.cofomo-search__field /deep/ .control
  &, & input
    width: 100%

.cofomo-search__field /deep/ .input
  height: 3.5em
  padding-left: 3.5em !important
  border-radius: 0px
  border: 0

  &, &:hover
    z-index: auto !important

.cofomo-search__field img
  position: absolute
  z-index: 1
  top: 15px
  left: 15px

// Tabs

.cofomo-search__tabs
  background: white

.cofomo-search__tab
  border-right: 1px solid $grey-lighter
  border-bottom: 4px solid

.cofomo-search__tab--cities
  border-bottom-color: $info

.cofomo-search__tab--categories
  border-bottom-color: $success

.cofomo-search__tab:last-child
  border-right: none

.cofomo-search__tab a
  display: flex
  height: 40px
  justify-content: center
  align-items: center
  font-size: 0.70em
  color: $grey

.cofomo-search__tab .icon
  transition: transform ease-out .2s

.cofomo-search__tab /deep/ .icon i
  font-size: 18px

.cofomo-search__tab--is-active .icon
  transform: rotate(-180deg)

.cofomo-search__tab__text
  font-weight: 600
  letter-spacing: 0.8px
  text-transform: uppercase
  margin-right: .55rem

.cofomo-search__tabs--is-active .cofomo-search__tab:not(.cofomo-search__tab--is-active)
  border-bottom-color: $grey-light

.cofomo-search__tabs--is-active .cofomo-search__tab:not(.cofomo-search__tab--is-active) a
  color: $grey-light

// Filter drawer

.cofomo-search__drawer
  background: white
  box-shadow: 0 1px 10px $grey-lighter
  padding: .2rem .5rem
  position: absolute
  width: 100%
  z-index: 20
  line-height: 2.4rem
  overflow-y: auto

  // IE11
  left: 0
  right: 0

// Tags

.cofomo-search__tag
  font-size: .9em
  margin-right: 5px

.cofomo-search__tag--inactive
  background: transparent
  border: 1px solid $grey-light
  color: $grey

  &:hover
    background: rgb(250, 250, 250)

.cofomo-search__tag--inactive.is-info:hover
  color: $info

.cofomo-search__tag--inactive.is-success:hover
  color: $success

.cofomo-search__tag sup
  margin-left: .3rem
</style>

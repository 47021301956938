<template>
  <div class="cofomo-background-video">
    <div class="shader" :style="{
      background: shaderColor,
      opacity: shaderOpacity
    }"></div>

    <img v-if="isMobile" ref="image" :src="cover" data-object-fit="cover" />

    <video v-else ref="video" data-object-fit="cover" preload="metadata" loop autoplay muted>
      <slot></slot>
    </video>
  </div>
</template>

<script>
import { debounce } from 'lodash'

const isTouch = (
  ('ontouchstart' in window) ||
  (window.navigator && window.navigator.msPointerEnabled && window.MSGesture) ||
  (window.DocumentTouch && document instanceof DocumentTouch)
)

const isIe = (
  window.navigator.userAgent.indexOf('MSIE') > 0 ||
  window.navigator.userAgent.match(/Trident.*rv\:11\./)
)

export default {
  name: 'cofomo-background-video',

  props: {
    cover: String,
    minimum: { type: Number, default: 640 },
    shaderColor: { type: String, default: 'rgb(0, 0, 0)' },
    shaderOpacity: { type: Number, default: 0.3 }
  },

  data () {
    return {
      isMobile: this.checkIfMobile()
    }
  },

  watch: {
    isMobile () {
      this.$nextTick(() => window.objectFitPolyfill(document.querySelectorAll('[data-object-fit]')))
    }
  },

  methods: {
    checkIfMobile () {
      return (isTouch && !isIe) || window.innerWidth < this.minimum
    },

    resizeHandler: debounce(function () {
      this.isMobile = this.checkIfMobile()
    }, 200),

    pause () {
      if (this.$refs.video) this.$refs.video.pause()
    },

    play () {
      if (this.$refs.video) this.$refs.video.play()
    }
  },

  mounted () {
    this.$events.on('videos-pause', this.pause)
    this.$events.on('videos-unpause', this.play)

    window.addEventListener('resize', this.resizeHandler)
    // window.objectFitPolyfill(document.querySelectorAll('[data-object-fit]'))
  },

  beforeDestroy () {
    this.$events.off('videos-pause')
    this.$events.off('videos-unpause')

    window.removeEventListener('resize', this.resizeHandler)
  }
}
</script>

<style lang="sass" scoped>
  .cofomo-background-video
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    overflow: hidden

  .shader
    position: absolute
    width: 100%
    height: 100%

  video, img
    width: 100%
    height: 100%
    object-fit: cover
    object-position: 50% 0%
</style>

<template>
  <div class="cofomo-apply-privacy-policy modal-card">
    <header class="modal-card-head has-text-centered">
      <span class="close-button" @click="$emit('close') && $parent.$emit('close')">
        <b-icon icon="close"></b-icon>
      </span>

      <span class="is-cofomo-caps modal-card-title">
        {{ i18n('modals/apply/privacy-policy/title') }}
      </span>
    </header>

    <section class="modal-card-body">
      <div class="has-text-left" v-html="i18n('modals/apply/privacy-policy/text')">
      </div>

      <hr class="space space__5" />

    </section>

    <footer class="modal-card-foot has-text-centered">
      <a @click="$parent.$emit('go-to-chooser')">
        {{ i18n('modals/apply/privacy-policy/button') }}
      </a>
    </footer>
  </div>
</template>

<script>
  export default {
    name: 'cofomo-apply-privacy-policy',

    props: {
    },

    data () {
      return {}
    },

    methods: {
      goToChooser () {
        this.$parent.$emit('go-to-chooser')
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "../styles/vars.scss";

  .cofomo-apply-privacy-policy
    color: $black

  .modal-card-head
    position: relative
    justify-content: center

  .close-button
    position: absolute
    right: 1rem
    cursor: pointer
    top: 50%
    transform: translateY(-50%)

  .modal-card-title
    @media screen and (max-width: 700px)
      max-width: 80%

  .modal-card-body
    padding: 2rem

    +mobile
      padding: 1rem

  .modal-card-foot
    justify-content: center

  /deep/ .icon .mdi
    position: relative
    top: 1px
    font-size: 20px
    margin-right: 1rem

    &:before
      font-size: 20px
      height: 20px
</style>

<template>
  <nav class="cofomo-nav">
    <div class="container">
      <div class="level is-mobile">
        <div class="level-left">
          <div class="level-item">
            <router-link :to="{ name: 'Home' }" :title="i18n('home/link-title')">
              <img
                src="../assets/logo.svg"
                style="width: 162px; height: 25px"
                class="is-hidden-mobile"
              />
              <img
                src="../assets/logo-letter.svg"
                style="width: 25px; height: 25px"
                class="is-hidden-tablet"
              />
            </router-link>
          </div>

          <div class="level-item is-hidden-desktop">
            <router-link :to="{ name: 'Jobs' }" :title="i18n('home/go-to-jobs/link-title')">
              {{ i18n('home/go-to-jobs') }}
            </router-link>
          </div>

          <div class="level-item is-hidden-mobile">
            <router-link :to="{ name: 'Home', hash: '#benefits' }" :title="i18n('home/benefits/link-title')">
              {{ i18n('home/benefits') }}
            </router-link>
          </div>

          <div class="level-item is-hidden-mobile">
            <router-link :to="{ name: 'Home', hash: '#referral' }" :title="i18n('home/referral/link-title')">
              {{ i18n('home/referral') }}
            </router-link>
          </div>

          <div class="level-item is-hidden-mobile">
            <router-link :to="{ name: 'Home', hash: '#international' }" title="International">
              International
            </router-link>
          </div>

          <div class="level-item">
            <router-link :to="{ name: 'Home', hash: '#contact' }" :title="i18n('home/contact/link-title')">
              {{ i18n('home/contact') }}
            </router-link>
          </div>
        </div>

        <div class="level-right">
          <div class="level-item is-hidden-mobile  is-hidden-tablet-only is-hidden-widescreen">
            <a ref="more" href="#" @click.prevent="togglePopover">
              <span>{{ i18n('home/more') }}</span>
              <img src="/static/icons/chevron-down.svg" style="vertical-align: middle; margin-left: .25rem;" />
            </a>
          </div>

          <div class="level-item is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
            <a
              :href="websiteUrl('corporate', '/' + language.current)"
              target="_blank"
              :title="i18n('home/go-to-corporate/link-title')"
            >
              {{ i18n('home/go-to-corporate') }}
            </a>
          </div>

          <div class="level-item">
            <a
              :href="language.getAlternateLanguageUrl()"
              v-text="{ fr: 'EN', en: 'FR' }[language.current]"
              rel="noopener nofollow"
            ></a>
          </div>

          <div class="level-item is-hidden-mobile is-hidden-tablet-only">
            <router-link :to="{ name: 'Jobs' }" class="button is-cofomo-button is-primary" :title="i18n('home/go-to-jobs/link-title')">
              {{ i18n('home/go-to-jobs') }}
            </router-link>
          </div>

          <div class="level-item is-hidden-desktop">
            <a ref="hamburger" href="#" @click.prevent="togglePopover">
              <img src="/static/icons/menu.svg" style="margin-top: 2px; width: 25px" />
            </a>
          </div>

          <transition name="slide-up">
            <ul ref="popover" class="more-popover is-hidden-widescreen" v-show="isPopoverActive">
              <li class="is-hidden-tablet">
                <router-link :to="{ name: 'Home', hash: '#benefits' }" :title="i18n('home/benefits/link-title')">
                  {{ i18n('home/benefits') }}
                </router-link>
              </li>

              <li class="is-hidden-tablet">
                <router-link :to="{ name: 'Home', hash: '#referral' }" :title="i18n('home/referral/link-title')">
                  {{ i18n('home/referral') }}
                </router-link>
              </li>

              <li class="is-hidden-tablet">
                <router-link :to="{ name: 'Home', hash: '#international' }" title="International">
                  International
                </router-link>
              </li>

              <li>
                <a :href="websiteUrl('corporate', '')" target="_blank" :title="i18n('home/go-to-corporate/link-title')">
                  {{ i18n('home/go-to-corporate') }}
                </a>
              </li>

            </ul>
          </transition>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import language from '@/scripts/language'

export default {
  name: 'cofomo-nav',

  data () {
    return {
      isPopoverActive: false
    }
  },

  computed: {
    language () {
      return language
    }
  },

  methods: {
    togglePopover () {
      this.isPopoverActive = !this.isPopoverActive
    },

    outsideClick (e) {
      const els = [this.$refs.more, this.$refs.hamburger, this.$refs.popover]
      if (!els.some(el => el.contains(e.target))) {
        this.isPopoverActive = false
      }
    }
  },

  mounted () {
    window.addEventListener('click', this.outsideClick)
    window.addEventListener('touchstart', this.outsideClick)
  },

  beforeDestroy () {
    window.removeEventListener('click', this.outsideClick)
    window.removeEventListener('touchstart', this.outsideClick)
  }
}
</script>

<style scoped lang="sass">
  @import "../styles/vars.scss";

  .cofomo-nav
    // IE11
    width: 100%

    border-bottom: 1px solid $grey-lighter
    height: 80px
    margin-bottom: 0
    z-index: 11
    background: white
    top: 0

  .cofomo-nav > .container
    height: 100%

  .cofomo-nav > .container > .level
    height: 100%
    padding: 0 1rem

  .level-left .level-item:not(:last-child)
    margin-right: 1rem

  .level-right .level-item:not(:last-child)
    margin-right: 0

  .level-right .level-item:not(:first-child)
    margin-left: 1rem

  .level-item > a, .more-popover a
    font-weight: 600
    color: $primary-grey
    transition: color 0.2s
    text-transform: uppercase
    line-height: 1.2
    font-size: 12px
    letter-spacing: 1px

    &:hover
      color: $primary

  .level-right
    height: 40px
    position: relative

  .more-popover
    position: absolute
    z-index: 1000
    top: 100%
    right: 0
    margin-top: 0px
    width: 190px
    background: #fff
    line-height: 50px
    padding: 1rem 2rem
    border-radius: 5px
    border: 1px solid #e5e5e5
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
    display: inline-block
    list-style-type: none
    margin-left: 0
    white-space: nowrap

    +desktop
      right: 210px

  .more-popover li
    display: block
    margin: 0
    line-height: 40px

</style>

<template>
<div class="Home">
  <!-- Navbar -->
  <cofomo-nav v-sticky-state></cofomo-nav>

  <!-- Header with background video -->
  <header>
    <cofomo-background-video
      ref="video"
      cover="/static/videos/bg.jpg"
      :shader-opacity="0.35"
    >
      <source src="/static/videos/bg.webm" type="video/webm" />
      <source src="/static/videos/bg.mp4" type="video/mp4" />
    </cofomo-background-video>

    <div class="container has-text-centered">
      <div class="transition-home-to-jobs">
        <hr class="header-space space" />

        <div class="is-cofomo-caps is-medium" style="font-weight: 600; color: white">
          {{ i18n('home/hero/site-name') }}
        </div>

        <h1 class="is-cofomo-title-extra-large">
          {{ i18n('home/hero/title') }}
        </h1>

        <strong class="is-cofomo-subtitle" style="color: white; font-weight: 400;">
          {{ i18n('home/hero/subtitle') }}
        </strong>
      </div>

      <hr class="space space__4" />

      <div class="fake-search material-shadow" @click="navigateToJobs">
        <img src="/static/slimicons/ui-search.svg" style="height: 25px" />
        <span>{{ i18n('search/placeholder') }}</span>
      </div>

      <hr class="space space__4" />

      <cofomo-apply ref="apply"></cofomo-apply>

      <button
        class="button is-cofomo-button is-white gtm-btn-postuler"
        @click.prevent="$refs.apply.launchChooser()"
      >
        {{ i18n('home/hero/apply') }}
      </button>
    </div>
  </header>

  <hr class="space space__separator" />

  <!-- Recent jobs and apply -->
  <section style="padding: 3rem 0;">
    <div class="container">
      <div class="columns is-centered">
        <div class="column is-10-tablet is-two-thirds-desktop has-text-centered">
          <h2 class="is-cofomo-caps">
            {{ i18n('home/jobs/latest') }}
          </h2>

          <hr class="space space__3" />

          <cofomo-job-level
            :jobs="recentJobs"
            column-class="is-full is-one-third-tablet"
            @job-click="navigateToJob($event)"
            class="recent-jobs"
          ></cofomo-job-level>

          <hr class="space space__4" />

          <p>
            <button class="button is-cofomo-button is-outlined" @click="navigateToJobs">
              {{ i18n('home/jobs/see-all') }}
            </button>
          </p>
        </div>
      </div>
    </div>
  </section>

  <hr class="space space__separator" />

  <!-- Benefits -->
  <div style="background: white">
    <hr class="space space__separator" />

    <section id="benefits" class="has-text-centered">
      <div class="is-cofomo-caps is-medium" style="font-weight: 600">
        {{ i18n('home/benefits') }}
      </div>

      <h2 class="is-cofomo-title-medium">
        {{ i18n('home/benefits/title') }}
      </h2>

      <div class="is-cofomo-subtitle">
        {{ i18n('home/benefits/subtitle') }}
      </div>

      <hr class="space space__2">

      <div class="columns is-centered">
        <div class="column is-10-tablet is-two-thirds-desktop">
          <div class="columns is-multiline">
            <div class="column is-half has-text-left" v-for="(_, i) in Array(6)">
              <div class="media scroll-reveal">
                <figure class="media-left">
                  <img class="icon is-medium" :src="slimicon(`benefits[${i}]`)" />
                </figure>

                <div class="media-center">
                  <h3 class="is-cofomo-title-small">
                    {{ i18n(`home/benefits/element-${i+1}/title`) }}
                  </h3>

                  <p>
                    {{ i18n(`home/benefits/element-${i+1}/subtitle`) }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr class="space space__2">

      <div class="columns is-centered">
        <div class="column is-6-tablet">
          <cofomo-youtube-embed
            :id="i18n('home/video-youtube-id')"
          ></cofomo-youtube-embed>
        </div>
      </div>

      <hr class="space space__2">

      <a
        class="button is-cofomo-button is-outlined"
        :title="i18n('home/contact/link-title')"
        @click="scrollToSelector('#contact', 250)"
      >
        {{ i18n('home/benefits/button') }}
      </a>
    </section>
  </div>

  <hr class="space space__separator" />

  <!-- referral -->
  <section id="referral" class="has-text-centered is-text-white">
    <div class="container">
      <div class="is-cofomo-caps is-medium" style="font-weight: 600">{{ i18n('home/referral') }}</div>
      <h2 class="is-cofomo-title-medium">{{ i18n('home/referral/title') }}</h2>
      <div class="is-cofomo-subtitle">{{ i18n('home/referral/subtitle') }}</div>

      <hr class="space space__2">

      <cofomo-apply ref="refer" :is-referral="true"></cofomo-apply>

      <button
        class="button is-cofomo-button is-white gtm-btn-referer"
        @click.prevent="$refs.refer.launchChooser()"
      >
        {{ i18n('home/referral/button') }}
      </button>

      <hr class="space space__4">

      <p class="notice is-medium">
        {{ i18n('home/referral/notice') }}
      </p>
    </div>
  </section>

  <hr class="space space__separator" />

  <International></International>

  <hr class="space space__separator" />

  <!-- Contact -->
  <section id="contact" class="has-text-centered">
    <div class="container">
      <h2 class="is-cofomo-title-medium">{{ i18n('home/contact/title') }}</h2>
      <div class="is-cofomo-subtitle">{{ i18n('home/contact/subtitle') }}</div>

      <hr class="space space__2">

      <div class="columns is-centered">
        <div class="column is-10-tablet is-12-desktop">
          <div class="columns is-multiline">
            <div class="map-container column is-full is-half-tablet is-4-widescreen has-text-left" v-for="city in ['montreal', 'quebec', 'ottawa']">
              <div class="map">
                <h3 class="map__name has-text-centered">{{ i18n(`home/contact/${city}/name`) }}</h3>

                <div class="map__group">
                  <img src="/static/slimicons/camping-place-map.svg" />
                  {{ i18n('home/contact/address') }}
                </div>

                <p>
                  <a target="_blank" :href="'https://goo.gl/maps/' + i18n(`home/contact/${city}/google`)">
                    {{ i18n(`home/contact/${city}/address`) }},
                    {{ i18n(`home/contact/${city}/city`) }},
                    {{ i18n(`home/contact/${city}/postal`) }}
                  </a>
                </p>

                <div class="map__group">
                  <img src="/static/slimicons/hardware-iphone.svg" />
                  {{ i18n('home/contact/phone') }}
                </div>

                <p>
                  <a :href="makeTel(i18n(`home/contact/${city}/phone`))">
                    {{ i18n(`home/contact/${city}/phone`) }}
                  </a>
                </p>

                <p v-if="i18n(`home/contact/${city}/phone-notice`)">
                  {{ i18n(`home/contact/${city}/phone-notice`) }}
                </p>

                <div class="map__group">
                  <img src="/static/slimicons/mail-mail-address.svg" />
                  {{ i18n('home/contact/email') }}
                </div>

                <p>
                  <a :href="'mailto:' + i18n(`home/contact/${city}/email`)">
                    {{ i18n(`home/contact/${city}/email`) }}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
import VueScrollTo from 'vue-scrollto'

import CofomoApply from '@/components/CofomoApply'
import CofomoBackgroundVideo from '@/components/CofomoBackgroundVideo'
import CofomoJobCard from '@/components/CofomoJobCard'
import CofomoJobLevel from '@/components/CofomoJobLevel'
import CofomoNav from '@/components/CofomoNav'
import CofomoYoutubeEmbed from '@/components/CofomoYoutubeEmbed'

import International from '@/pages/International'

import language from '@/scripts/language'
import ScrollReveal from '@/scripts/scrollreveal'
import gaJobFormat from '@/scripts/ga-job-format'

export default {
  name: 'home',

  components: {
    CofomoApply,
    CofomoBackgroundVideo,
    CofomoJobCard,
    CofomoJobLevel,
    CofomoNav,
    CofomoYoutubeEmbed,

    International
  },

  data () {
    return {
      scrollReveal: null
    }
  },

  computed: {
    recentJobs () {
      return language.current === 'en'
        ? window.static.firstJobOrdersInEnglish
        : window.static.firstJobOrders
    }
  },

  methods: {
    navigateToJob (job) {
      this.$router.push({
        name: 'Job',
        params: { id: job.id }
      })
    },

    navigateToJobs () {
      this.$refs.video.pause()
      this.$router.push({ name: 'Jobs' })
    },

    scrollToSelector (sel, speed) {
      VueScrollTo.scrollTo(document.querySelector(sel), speed, {
        easing: 'ease-out',
        cancelable: true,
        offset: -80
      })
    },

    makeTel (str) {
      return 'tel:' + str.replace(/[\s-]/g, '')
    }
  },

  watch: {
    '$route' (to, from) {
      console.log('ROUTE CHANGED', {to, from})
      const hash = this.$route.hash
      if (hash === '') return
      this.scrollToSelector(hash, 250)
    }
  },

  mounted () {
    this.scrollReveal = new ScrollReveal({ duration: 500 })
    this.scrollReveal.reveal('.scroll-reveal')
    this.scrollReveal.reveal('.recent-jobs .column:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3))', { duration: 1500 })

    // Scroll to hash on pageload
    const hash = this.$route.hash
    if (hash !== '') {
      setTimeout(() => this.scrollToSelector(hash, 500), 1000)
    }

    const gaEvent = {
      event: 'list impression',
      list: 'list view',
      listing: gaJobFormat(this.recentJobs)
    }

    console.info('Sending GA Event', gaEvent)
    window.dataLayer.push(gaEvent)
  },

  beforeDestroy () {
    this.scrollReveal.destroy()
  }
}
</script>

<style lang="sass" scoped>
  @import "~bulma/sass/utilities/_all";

  header
    position: relative
    padding: 0 1rem

  header > .container
    padding-bottom: 7rem

  header .hero
    background: transparent

  header .hero-body
    margin-top: 2rem

  header h1, header h2, header h4
    color: white !important

  .header-space
    height: 18vh

  section
    padding: 5rem 0

    +mobile
      padding-left: 1rem
      padding-right: 1rem

  .fake-search
    position: relative
    max-width: 759px
    background: white
    height: 57px
    line-height: 57px
    margin: 0 auto
    padding-left: 3.5em
    color: rgb(122, 122, 122)
    text-align: left
    cursor: text

    img
      position: absolute
      z-index: 1
      top: 15px
      left: 15px

  #referral
    position: relative
    background-image: url("/static/images/referral.jpg")
    background-size: cover
    background-position: 50% 0%

  #benefits, #contact
    .is-cofomo-title, .is-cofomo-title-medium
      color: #3c4146

    .is-cofomo-title-small
      color: #3c4146
      font-weight: 600

  #contact
    background: rgb(249, 249, 249)

  .map-container
    display: flex

  .map
    width: 100%
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)
    padding: 2rem
    font-size: .93060486rem
    background: white

  .map__name
    font-size: 1.333rem
    font-weight: 500
    color: rgb(74, 74, 74)
    padding-bottom: 1rem
    border-bottom: 1px solid $grey-lighter
    margin-bottom: 1.5rem

  .map__group
    margin-top: .5rem
    text-transform: uppercase
    font-weight: 500

  .map__group img
    height: 1em
    margin-right: .25rem
    position: relative
    top: 2px

  .map p
    margin-left: 23px
</style>

<style lang="sass">
  html.is-bad-ios .header-space
    height: 80px
</style>

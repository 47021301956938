export default function preventScrollPropagation (e) {
  const scrollTop = this.scrollTop
  const scrollHeight = this.scrollHeight
  const height = parseFloat(window.getComputedStyle(this, null).getPropertyValue('height'))
  const delta = e.type == 'DOMMouseScroll' ? (e.detail * -40) : e.wheelDelta
  const up = delta > 0

  const prevent = () => {
    e.stopPropagation()
    e.preventDefault()
    e.returnValue = false
    return false
  }

  // Scrolling down, but this will take us past the bottom.
  if (!up && -delta > scrollHeight - height - scrollTop) {
    this.scrollTop = scrollHeight
    return prevent()

  // Scrolling up, but this will take us past the top.
  } else if (up && delta > scrollTop) {
    this.scrollTop = 0
    return prevent()
  }
}

<template>
<div id="international" class="international">
  <section class="international__section">
    <section class="has-text-centered" style="padding: 6rem 0">
      <div class="container">
        <div style="max-width: 700px; margin: 0 auto; padding: 0 0.75rem">
          <div class="is-cofomo-caps is-medium" style="font-weight: 600">
            {{ i18n(`home/international`) }}
          </div>

          <h2 class="is-cofomo-title-medium">
            {{ i18n(`home/international/title`) }}
          </h2>

          <div class="is-cofomo-subtitle">
            {{ i18n(`home/international/subtitle`) }}
          </div>
        </div>

        <hr class="space space__2">

        <div class="columns is-centered">
          <div class="column is-10-tablet is-two-thirds-desktop">
            <div class="columns is-multiline">
              <div
                class="column is-half has-text-left"
                v-for="(_, i) in Array(3)"
                :key="i"
              >
                <div class="media scroll-reveal">
                  <figure class="media-left">
                    <img class="icon is-medium" :src="slimicon(`international[${i}]`)" />
                  </figure>

                  <div class="media-center">
                    <h3 class="is-cofomo-title-small">
                      {{ i18n(`home/international/element-${i+1}/title`) }}
                    </h3>

                    <p>
                      {{ i18n(`home/international/element-${i+1}/subtitle`) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="padding: 0 0.75rem; font-style: italic; font-size: 0.9em;">
          {{ i18n(`home/international/notice`) }}
        </div>

        <hr class="space space__2">

        <div class="columns is-centered">
          <div class="column is-6-tablet">
            <cofomo-youtube-embed
              id="PA_plJ2C_iA"
            ></cofomo-youtube-embed>
          </div>
        </div>

        <hr class="space space__2">

        <div style="padding: 0 0.75rem">
          <div class="toggle">
            <a
              href="#"
              class="button"
              :class="{ 'is-active': tabI === 0 }"
              @click.prevent="tabI = 0"
            >
              {{ i18n(`home/international/tabs/seeking/title`) }}
            </a>

            <a
              href="#"
              class="button"
              :class="{ 'is-active': tabI === 1 }"
              @click.prevent="tabI = 1"
            >
              {{ i18n(`home/international/tabs/links/title`) }}
            </a>
          </div>
        </div>

        <hr class="space space__3">

        <div class="columns is-centered">
          <div class="column is-10-tablet is-two-thirds-desktop">
            <div class="box">
              <div class="columns is-multiline">
                <div
                  v-if="tabI === 0"
                  v-for="(text, i) in profiles"
                  :key="i"
                  class="column is-half has-text-left box__element"
                  style="text-transform: uppercase"
                >
                  {{ text }}
                </div>

                <div
                  v-if="tabI === 1"
                  v-for="({ link, text }, i) in links"
                  :key="i"
                  class="column is-half has-text-left box__element"
                >
                  <a :href="link" target="_blank">{{ text }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="space space__2">

        <div class="columns is-centered">
          <div class="column is-10">
            <div class="box">

              <div class="field">
                <label for="international-office" class="label">
                  {{i18n('home/international/offices/label')}}
                </label>
                <div class="control">
                  <b-select
                    name="international-office"
                    v-model="international.value"
                    :placeholder="i18n('home/international/offices/default/text')">
                    <option value="montreal">{{i18n('home/international/offices/montreal/text')}}</option>
                    <option value="quebec">{{i18n('home/international/offices/quebec/text')}}</option>
                    <option value="ottawa">{{i18n('home/international/offices/ottawa/text')}}</option>
                  </b-select>
                </div>
              </div>

              <hr class="space space__6">

              <p>
                <a
                  :href="`mailto:${officeMail}`"
                  :disabled="!hasChosenOffice"
                  @click="preventMailWithoutOffice"
                  class="button is-cofomo-button is-primary"
                  style="white-space: normal; margin: 0 0.75rem; line-height: 1.7 !important; padding: 1rem !important;"
                >
                  {{ i18n('home/international/tabs/button/text') }}
                </a>
              </p>

            </div>
          </div>
        </div>

      </div>
    </section>
  </section>
</div>
</template>

<script>
import CofomoNav from '@/components/CofomoNav'
import CofomoYoutubeEmbed from '@/components/CofomoYoutubeEmbed'

import language from '@/scripts/language'

const profiles = {
  fr: [
    'Analyste Assurance-Qualité',
    'Analyste Programmeur Java',
    'Analyste fonctionnel',
    'Analyste Programmeur .NET',
    'Analyste Programmeur ETL (MS BI)',
  ],
  en: [
    'Software QA Analyst',
    'Fullstack Java Developer',
    'Functional Analyst',
    'Fullstack .NET developer',
    'MS BI Developer'
  ]
}

const links = {
  fr: [
    {
      link: 'https://www.immigration-quebec.gouv.qc.ca/publications/fr/divers/apprendrelequebec.pdf',
      text: 'Guide «Apprendre le Québec» du Ministère de l’Immigration, de la Diversité et de l’Inclusion du Québec',
    },
    {
      link: 'https://immigrantquebec.com/fr/guides/immigrer-au-quebec/',
      text: 'Guide «Immigrer au Québec» d’Immigrant Québec',
    },
    {
      link: 'https://immigrantquebec.com/fr/consulter/guides/vivre-a-montreal',
      text: 'Guide «Vivre à Montréal» d’Immigrant Québec',
    },
    {
      link: 'https://immigrantquebec.com/fr/consulter/guides/vivre-a-quebec',
      text: 'Guide «Vivre à Québec» d’Immigrant Québec',
    },
  ],
  en: [
    {
      link: 'https://immigrantquebec.com/fr/consulter/guides/living-in-quebec-city',
      text: '"Living in Québec City and its surrounding" free guide by Immigrant Québec',
    },
    {
      link: 'https://immigrantquebec.com/fr/consulter/guides/living-in-quebec-city',
      text: '“The free mobility guide (Québec, Canada)” by Immigrant Québec',
    },
  ]
}

export default {
  name: 'international',
  components: { CofomoYoutubeEmbed },
  data () {
    return {
      tabI: 0,
      international: {
        value: null
      }
    }
  },
  computed: {
    profiles () {
      return profiles[language.current]
    },
    links () {
      return links[language.current]
    },
    hasChosenOffice () {
      return this.international.value
    },
    officeMail () {
      return {
        null: this.i18n('home/international/offices/default/mail'),
        montreal: this.i18n('home/international/offices/montreal/mail'),
        quebec: this.i18n('home/international/offices/quebec/mail'),
        ottawa: this.i18n('home/international/offices/ottawa/mail'),
     }[this.international.value]
    }
  },
  methods: {
    preventMailWithoutOffice (event) {
      if (!this.hasChosenOffice) {
        event.preventDefault();
      }
    }
  }
}
</script>

<style lang="sass" scoped>
</style>

<style lang="sass">
.international__section
  background: white

.toggle
  display: flex
  justify-content: stretch
  margin: 0 auto;
  max-width: 400px;

.toggle .button
  width: 100%
  color: black
  background: white
  transition: color .25s, background .25s

  &:not(:last-child)
    border-top-right-radius: 0
    border-bottom-right-radius: 0

  &:not(:first-child)
    border-top-left-radius: 0
    border-bottom-left-radius: 0
    border-left: 0

  &, &:active
    outline: none

.toggle .button.is-active
  background: #00369a
  color: white
  border-color: #00369a

.box
  padding: 1.625rem 1.25rem 2rem 1.25rem;

.box__element
  line-height: 1
  font-weight: 500
  padding-bottom: 0
</style>

import store from '@/store'

class Language {
  constructor (langs = [], defaultLang) {
    this.langs = langs
    this.defaultLang = defaultLang || this.langs[0]

    this.current = this.detect()

    if (!window.static) return

    store.lastSearchedJobs = this.current === 'en'
      ? window.static.firstPageInEnglish
      : window.static.firstPage
  }

  detect () {
    const parts = document.location.pathname.split('/')
    if (this.langs.includes(parts[1])) return parts[1]

    if (!window.navigator) return this.defaultLang

    let found = []
    if (navigator.language) found.push(navigator.language)
    if (navigator.userLanguage) found.push(navigator.userLanguage)
    for (let lang of navigator.languages || []) found.push(lang)

    found = found
      .map(lang => lang.split('-')[0])
      .filter(lang => this.langs.includes(lang))

    return found.length === 0 ? this.defaultLang : found[0]
  }

  getLanguageUrl (lang, url = document.location.pathname) {
    let parts = url.split('/')
    if (!this.langs.includes(parts[1])) return url

    if (this.langs.includes(lang)) parts[1] = lang
    return parts.join('/')
  }

  getAlternateLanguageUrl (url = document.location.pathname) {
    let parts = url.split('/')
    if (!this.langs.includes(parts[1])) return url

    parts[1] = { fr: 'en', en: 'fr' }[parts[1]]
    return parts.join('/')
  }

  // changeTo (lang, removeApply = true) {
  //   if (!this.langs.includes(lang)) return false
  //   window.location = this.getAlternateLanguageUrl()
  // }
}

export default new Language(['fr', 'en'])
